import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useCallback, useEffect} from 'react';
import {
  Pressable,
  StatusBar,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {RootStackParamList, TabsParamList} from '../navigation/types';
import {GameState, useGameStore} from '../state/game';
import {MiniBoard} from '../components/MiniBoard';
import {formatDuration} from '../components/Timer';
import {formatDifficulty} from '../components/Board';
import {DailyChallengeCard} from '../components/DailyChallengeCard';
import {
  Storage,
  globalStateKey,
  streakProtectionKey,
  streakStatusKey,
} from '../storage';
import {useMMKVNumber, useMMKVObject} from 'react-native-mmkv';
import {Button} from '../components/Button';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {CompositeScreenProps, useFocusEffect} from '@react-navigation/native';
import {Analytics} from '../analytics';
import {SafeAreaView} from 'react-native-safe-area-context';
import {AchievementsHeader} from '../components/AchievementsHeader';
import {scheduleReminders} from '../core/reminders';
import {format} from 'date-fns';
import {StreakStatus, getStreakDays} from '../core/streak';
import {useTotalScore} from '../hooks/useTotalScore';

let streakProtectionShown = false;
export function HomeScreen({
  navigation,
}: CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'Home'>,
  NativeStackScreenProps<RootStackParamList>
>) {
  const backgroundStyle: ViewStyle = {
    backgroundColor: 'black',
    alignItems: 'center',
    flex: 1,
  };
  const {fontScale} = useWindowDimensions();
  const [game] = useMMKVObject<GameState>(globalStateKey);
  const totalScore = useTotalScore();
  const [streakStatus] = useMMKVObject<StreakStatus>(streakStatusKey);
  const [streakProtection] = useMMKVNumber(streakProtectionKey);
  const gameState = game?.gameState || 'none';
  const hasActiveGame = gameState === 'incomplete';
  const gameDurationInSeconds = game?.gameDurationInSeconds;
  const difficulty = game?.difficulty;

  useEffect(() => {
    const today = format(new Date(), 'yyyyMMdd');
    const dailyGameString = Storage.getDailyState(today);
    let excludeToday = false;
    if (dailyGameString) {
      try {
        const dailyGame = JSON.parse(dailyGameString) as GameState;
        excludeToday = dailyGame.gameState === 'completed';
      } catch (err) {
        console.log('failed to parse daily game');
      }
    }
    scheduleReminders({excludeToday});
  }, [navigation]);

  const showStreakProtection = useCallback(() => {
    // Show streak protection sheet
    if (
      streakStatus?.numberOfDays &&
      streakStatus?.numberOfDays > 0 &&
      !streakProtection &&
      !streakProtectionShown
    ) {
      navigation.navigate('StreakProtection');
      streakProtectionShown = true;
    }
  }, [navigation, streakStatus?.numberOfDays, streakProtection]);

  useFocusEffect(showStreakProtection);

  return (
    <SafeAreaView style={backgroundStyle}>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={backgroundStyle.backgroundColor}
      />
      <AchievementsHeader />
      <Text maxFontSizeMultiplier={2} style={styles.title}>
        Cross Math
      </Text>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: 8,
        }}>
        <Pressable
          style={{padding: 4, borderRadius: 4}}
          hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
          onPress={() => {
            navigation.navigate('StreakProtection');
          }}>
          <Text style={[styles.score]}>
            🔥 {getStreakDays(streakStatus) + ' '}
            day streak
          </Text>
          {!!streakProtection && (
            <Text
              style={{
                color: 'white',
                alignSelf: 'center',
                fontSize: 12,
                fontWeight: '600',
                position: 'absolute',
                left: 12,
                top: 12 * (fontScale * 1.2),
              }}>
              🛡️
            </Text>
          )}
        </Pressable>
        <Text style={styles.score}>{'  |  '}</Text>
        <Text style={styles.score}>
          {(totalScore || 0)?.toLocaleString()} points 👑
        </Text>
      </View>
      <View style={styles.dailyContainer}>
        <DailyChallengeCard />
      </View>
      {hasActiveGame && game && (
        <>
          <MiniBoard
            board={game?.board}
            candidateNumbers={game.candidateNumbers}
            hideItems={true}
          />
          <Pressable
            style={({pressed}) => ({
              backgroundColor: '#4169E1',
              alignSelf: 'center',
              alignItems: 'center',
              borderRadius: 40,
              paddingVertical: 8,
              width: 300,
              marginTop: 20,
              opacity: pressed ? 0.7 : 1,
            })}
            onPress={() => {
              Analytics.logEvent('click_continue_game');
              useGameStore.setState(game);
              navigation.navigate('Game');
            }}>
            <Text maxFontSizeMultiplier={2} style={styles.continueText}>
              {'Continue'}
            </Text>
            <Text maxFontSizeMultiplier={2} style={styles.continueGameInfo}>
              {formatDifficulty(difficulty!) +
                ' ' +
                formatDuration(gameDurationInSeconds!)}
            </Text>
          </Pressable>
        </>
      )}

      <Button
        style={styles.newGameButton}
        onPress={() => {
          navigation.navigate('DifficultyScreen');
          Analytics.logEvent('click_new_game');
        }}
        variant={!hasActiveGame ? 'primary' : 'secondary'}>
        {'New Game'}
      </Button>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  dailyContainer: {flex: 1, justifyContent: 'center'},
  newGameButton: {
    paddingVertical: 4,
    marginTop: 16,
    marginBottom: 16,
  },
  continueGameInfo: {
    color: 'white',
    opacity: 0.7,
    fontSize: 12,
    fontWeight: '500',
  },
  continueText: {
    color: 'white',
    paddingHorizontal: 48,

    fontSize: 18,
    fontWeight: '500',
  },
  score: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: '600',
  },
  title: {
    color: 'white',
    alignSelf: 'center',
    marginTop: 8,
    fontSize: 32,
  },
});
