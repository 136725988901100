import {BoardOperation} from './generator';

const easyMap1: BoardOperation[] = [
  {x: 3, y: 0, direction: 'left'},
  {x: 5, y: 0, direction: 'down'},
  {x: 2, y: 3, direction: 'left'},

  {x: 3, y: 2, direction: 'down'},
  {x: 3, y: 6, direction: 'left'},

  {x: 3, y: 8, direction: 'left'},
  {x: 5, y: 6, direction: 'down'},
];

const easyMap2: BoardOperation[] = [
  {x: 2, y: 1, direction: 'left'},
  {x: 4, y: 1, direction: 'down'},
  {x: 0, y: 5, direction: 'left'},

  {x: 2, y: 5, direction: 'down'},
  {x: 0, y: 7, direction: 'left'},

  {x: 2, y: 9, direction: 'left'},
  {x: 6, y: 5, direction: 'down'},

  {x: 6, y: 7, direction: 'left'},
  {x: 8, y: 3, direction: 'down'},
  {x: 10, y: 5, direction: 'down'},
];

const easyMap3: BoardOperation[] = [
  {x: 2, y: 1, direction: 'left'},
  {x: 2, y: 1, direction: 'down'},
  {x: 0, y: 3, direction: 'left'},
  {x: 0, y: 5, direction: 'left'},

  {x: 4, y: 5, direction: 'down'},
  {x: 2, y: 7, direction: 'left'},
  {x: 0, y: 9, direction: 'left'},

  {x: 6, y: 1, direction: 'down'},
  {x: 6, y: 5, direction: 'left'},
  {x: 10, y: 5, direction: 'down'},
];

const easyMap4: BoardOperation[] = [
  {x: 0, y: 1, direction: 'left'},
  {x: 0, y: 1, direction: 'down'},
  {x: 2, y: 1, direction: 'down'},
  {x: 0, y: 5, direction: 'left'},

  {x: 2, y: 3, direction: 'left'},
  {x: 6, y: 3, direction: 'down'},
  {x: 6, y: 5, direction: 'left'},
  {x: 6, y: 7, direction: 'left'},
  {x: 8, y: 3, direction: 'down'},

  {x: 10, y: 5, direction: 'down'},
  {x: 6, y: 9, direction: 'left'},
];

const easyMap5: BoardOperation[] = [
  {x: 2, y: 1, direction: 'left'},
  {x: 4, y: 1, direction: 'down'},
  {x: 0, y: 5, direction: 'left'},
  {x: 0, y: 5, direction: 'down'},

  {x: 2, y: 5, direction: 'down'},
  {x: 0, y: 7, direction: 'left'},
  {x: 2, y: 9, direction: 'left'},

  {x: 6, y: 5, direction: 'down'},
  {x: 6, y: 5, direction: 'left'},
  {x: 6, y: 7, direction: 'left'},
  {x: 10, y: 3, direction: 'down'},
];

const easyMap6: BoardOperation[] = [
  {x: 0, y: 5, direction: 'left'},
  {x: 2, y: 5, direction: 'down'},
  {x: 2, y: 9, direction: 'left'},

  {x: 4, y: 1, direction: 'down'},
  {x: 4, y: 3, direction: 'left'},

  {x: 6, y: 1, direction: 'left'},
  {x: 8, y: 1, direction: 'down'},
  {x: 10, y: 1, direction: 'down'},
];

const easyMap7: BoardOperation[] = [
  {x: 0, y: 1, direction: 'left'},

  {x: 0, y: 1, direction: 'down'},
  {x: 0, y: 5, direction: 'left'},
  {x: 4, y: 1, direction: 'down'},
  {x: 2, y: 3, direction: 'left'},
  {x: 2, y: 5, direction: 'down'},

  {x: 0, y: 9, direction: 'left'},
  {x: 2, y: 7, direction: 'left'},
  {x: 6, y: 5, direction: 'down'},
  {x: 6, y: 9, direction: 'left'},

  {x: 8, y: 5, direction: 'down'},
  {x: 10, y: 5, direction: 'down'},
];

const mediumMap1: BoardOperation[] = [
  {x: 0, y: 5, direction: 'down'},
  {x: 0, y: 5, direction: 'left'},
  {x: 2, y: 1, direction: 'down'},

  {x: 2, y: 1, direction: 'left'},
  {x: 2, y: 3, direction: 'left'},

  {x: 2, y: 7, direction: 'left'},
  {x: 4, y: 5, direction: 'down'},
  {x: 4, y: 9, direction: 'left'},

  {x: 6, y: 5, direction: 'down'},
  {x: 6, y: 5, direction: 'left'},

  {x: 8, y: 1, direction: 'down'},
  {x: 10, y: 5, direction: 'down'},
];

const mediumMap2: BoardOperation[] = [
  {x: 2, y: 0, direction: 'left'},
  {x: 4, y: 0, direction: 'down'},
  {x: 6, y: 0, direction: 'down'},
  {x: 6, y: 4, direction: 'left'},

  {x: 2, y: 2, direction: 'left'},
  {x: 2, y: 2, direction: 'down'},
  {x: 0, y: 4, direction: 'left'},
  {x: 0, y: 6, direction: 'left'},

  {x: 4, y: 6, direction: 'down'},
  {x: 0, y: 8, direction: 'left'},

  {x: 2, y: 10, direction: 'left'},
  {x: 6, y: 6, direction: 'down'},
];

const mediumMap3: BoardOperation[] = [
  {x: 4, y: 0, direction: 'left'},
  {x: 4, y: 0, direction: 'down'},
  {x: 6, y: 0, direction: 'down'},
  {x: 8, y: 0, direction: 'down'},

  {x: 0, y: 2, direction: 'left'},
  {x: 2, y: 4, direction: 'left'},
  {x: 2, y: 2, direction: 'down'},

  {x: 0, y: 6, direction: 'left'},
  {x: 4, y: 6, direction: 'down'},
  {x: 4, y: 8, direction: 'left'},
  {x: 2, y: 10, direction: 'left'},

  {x: 6, y: 6, direction: 'down'},
  {x: 6, y: 6, direction: 'left'},
  {x: 10, y: 4, direction: 'down'},
];

const hardMap1: BoardOperation[] = [
  {x: 0, y: 0, direction: 'left'},
  {x: 2, y: 0, direction: 'down'},
  {x: 2, y: 2, direction: 'left'},

  {x: 6, y: 0, direction: 'down'},
  {x: 6, y: 4, direction: 'left'},
  {x: 10, y: 2, direction: 'down'},
  {x: 8, y: 4, direction: 'down'},
  {x: 4, y: 8, direction: 'left'},

  {x: 0, y: 4, direction: 'left'},
  {x: 4, y: 2, direction: 'down'},
  {x: 2, y: 6, direction: 'left'},
  {x: 2, y: 6, direction: 'down'},

  {x: 0, y: 10, direction: 'left'},
  {x: 0, y: 6, direction: 'down'},
];

const hardMap2: BoardOperation[] = [
  {x: 4, y: 0, direction: 'left'},
  {x: 4, y: 0, direction: 'down'},
  {x: 6, y: 0, direction: 'down'},
  {x: 8, y: 0, direction: 'down'},
  {x: 6, y: 4, direction: 'left'},

  {x: 2, y: 2, direction: 'left'},
  {x: 2, y: 2, direction: 'down'},
  {x: 2, y: 6, direction: 'left'},
  {x: 4, y: 6, direction: 'down'},
  {x: 6, y: 6, direction: 'down'},
  {x: 6, y: 10, direction: 'left'},
  {x: 8, y: 6, direction: 'down'},

  {x: 0, y: 4, direction: 'left'},
  {x: 0, y: 4, direction: 'down'},
  {x: 0, y: 8, direction: 'left'},
  {x: 0, y: 10, direction: 'left'},
];

const hardMap3: BoardOperation[] = [
  {x: 0, y: 2, direction: 'down'},
  {x: 0, y: 4, direction: 'left'},
  {x: 0, y: 6, direction: 'left'},

  {x: 4, y: 6, direction: 'down'},
  {x: 2, y: 8, direction: 'left'},

  {x: 6, y: 6, direction: 'down'},
  {x: 6, y: 6, direction: 'left'},

  {x: 2, y: 0, direction: 'down'},
  {x: 2, y: 2, direction: 'left'},
  {x: 6, y: 0, direction: 'down'},
  {x: 4, y: 0, direction: 'left'},

  {x: 8, y: 0, direction: 'down'},
  {x: 6, y: 4, direction: 'left'},
];

export const extremeMap1: BoardOperation[] = [
  {x: 0, y: 0, direction: 'down'},
  {x: 0, y: 2, direction: 'left'},
  {x: 2, y: 0, direction: 'down'},
  {x: 2, y: 4, direction: 'left'},
  {x: 4, y: 2, direction: 'down'},

  {x: 6, y: 0, direction: 'down'},
  {x: 6, y: 0, direction: 'left'},
  {x: 6, y: 2, direction: 'left'},
  {x: 8, y: 0, direction: 'down'},
  {x: 10, y: 0, direction: 'down'},
  {x: 8, y: 4, direction: 'left'},

  {x: 2, y: 6, direction: 'left'},
  {x: 2, y: 6, direction: 'down'},
  {x: 6, y: 6, direction: 'down'},
  {x: 6, y: 8, direction: 'left'},
  {x: 10, y: 6, direction: 'down'},

  {x: 4, y: 8, direction: 'down'},
  {x: 4, y: 10, direction: 'left'},
  {x: 8, y: 8, direction: 'down'},
  {x: 4, y: 12, direction: 'left'},
];

export const easyMaps = [
  easyMap1,
  easyMap2,
  easyMap3,
  easyMap4,
  easyMap5,
  easyMap6,
  easyMap7,
];

export const mediumMaps = [mediumMap1, mediumMap2, mediumMap3];

export const hardMaps = [hardMap1, hardMap2, hardMap3];

export const extremeMaps = [extremeMap1, hardMap1, hardMap2];

export {
  easyMap1,
  easyMap2,
  easyMap3,
  easyMap4,
  easyMap5,
  easyMap6,
  easyMap7,
  mediumMap1,
  mediumMap2,
  mediumMap3,
  hardMap1,
  hardMap2,
  hardMap3,
};
