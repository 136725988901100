import {StyleSheet, Text, View} from 'react-native';

export function Tile({
  value,
  tileSize = 30,
  isPreview = false,
  largeFont = true,
}: {
  value: number | string;
  tileSize?: number;
  isPreview?: boolean;
  largeFont?: boolean;
}) {
  const hasValue = !!value;
  return (
    <View
      style={[
        {
          height: tileSize,
          width: tileSize,
          opacity: hasValue || isPreview ? 1 : 0.5,
        },
        styles.tile,
      ]}>
      {hasValue && (
        <Text
          allowFontScaling={false}
          selectable={false}
          style={[
            styles.tileText,
            {fontSize: largeFont ? tileSize * 0.7 : tileSize * 0.6},
          ]}>
          {value}
        </Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  tileText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
  },
  tile: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#604b30',
    borderRadius: 8,
    backgroundColor: '#FFD56C',
  },
});
