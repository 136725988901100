import {NativeModules} from 'react-native';
import {LeaderboardScores} from './types';
import {leaderBoardCache, setLeaderboardCache} from '../core/leaderboard';
import {Storage} from '../storage';

type GooglePlayGames = {
  init: () => void;
  signIn: () => Promise<void>;
  isAuthenticated: () => void;
  openLeaderBoard: (id: string) => Promise<void>;
  showAchievements: () => Promise<void>;
  incrementAchievement: (id: Achivements, value: number) => Promise<void>;
  submitScore: (id: LeaderBoards, score: number) => void;
  unlockAchievement: (id: Achivements) => Promise<void>;
  loadLeaderboardScores: (id: string, noCache?: boolean) => Promise<any[]>;
};

export enum Achivements {
  PLAY_1_GAME = 'CgkI77_axIkUEAIQBQ', // Finisher
  PLAY_5_EASY_GAMES = 'CgkI77_axIkUEAIQAg', // Easy Peasy
  PLAY_10_MEDIUM_GAMES = 'CgkI77_axIkUEAIQAw', // Medium Rare
  PLAY_15_HARD_GAMES = 'CgkI77_axIkUEAIQBA', // That's Hard
  DAILY_7 = 'CgkI77_axIkUEAIQBg',
  DAILY_14 = 'CgkI77_axIkUEAIQBw',
  DAILY_30 = 'CgkI77_axIkUEAIQCA',
  DAILY_60 = 'CgkI77_axIkUEAIQCQ',
  DAILY_90 = 'CgkI77_axIkUEAIQCg',
  DAILY_180 = 'CgkI77_axIkUEAIQCw',
  DAILY_365 = 'CgkI77_axIkUEAIQDA',
}

export enum LeaderBoards {
  TOTAL_SCORE = 'CgkI77_axIkUEAIQAQ',
}

export const GooglePlayGames: GooglePlayGames = {
  ...NativeModules.PlayGames,
  loadLeaderboardScores: (
    id: string,
    noCache = false,
  ): Promise<LeaderboardScores> => {
    if (leaderBoardCache && !noCache) {
      return Promise.resolve(leaderBoardCache);
    }
    return NativeModules.PlayGames.loadLeaderboardScores(id).then(
      (res: any) => {
        if (!res.localUser) {
          return Promise.reject('Local user is not defined');
        }
        if (res.localUser.score > Storage.getTotalScore()) {
          Storage.setTotalScore(res.localUser.score);
        }

        setLeaderboardCache(res);
        return Promise.resolve(res);
      },
    );
  },
};
