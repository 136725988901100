import {useMMKVObject} from 'react-native-mmkv';
import {settingsKey} from '../storage';
import {DEFAULT_SETTINGS, Settings, applySettings} from '../core/settings';

export function useSettings() {
  const [_settings = DEFAULT_SETTINGS, _setSettings] =
    useMMKVObject<Settings>(settingsKey);

  const settings = {...DEFAULT_SETTINGS, ..._settings};
  const setSettings = (update: Partial<Settings>) => {
    const newSetting = {...settings, ...update};
    _setSettings(newSetting);
    applySettings(newSetting);
  };
  return {settings, setSettings};
}
