import InAppReview from 'react-native-in-app-review';
import {Storage} from '../storage';
import {Analytics} from '../analytics';
import {Platform} from 'react-native';

export function showRateUsIfNeeded() {
  if (Storage.getIsRateUsShown()) {
    return;
  }
  if (Storage.getSessionCount() < 4) {
    return;
  }
  if (InAppReview.isAvailable()) {
    InAppReview.RequestInAppReview()
      .then(hasFlowFinishedSuccessfully => {
        Storage.setIsRateUsShown();
        Analytics.logEvent(`rate_us_shown_${Platform.OS}`, {
          success: hasFlowFinishedSuccessfully,
        });
      })
      .catch(error => {
        console.log('RequestInAppReview', error);
      });
  }
}
