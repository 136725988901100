import React, {useEffect} from 'react';
import {SafeAreaView, StatusBar, StyleSheet, View} from 'react-native';

import {Board} from '../components/Board';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/types';
import {useGameStore} from '../state/game';
import {AudioPlayer} from '../core/AudioPlayer';
import {BannerAd} from '../core/Ads/BannerAd';
import {isTodaysDailyGame} from '../utils/utils';
import {leaderBoardCache, loadScores} from '../core/leaderboard';

export function GameScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'Game'>) {
  const gameState = useGameStore(state => state.gameState);

  useEffect(() => {
    if (gameState === 'completed') {
      setTimeout(() => {
        AudioPlayer.playLevelPassed();

        const state = useGameStore.getState();
        if (isTodaysDailyGame(state.dailyDate, state.gameType)) {
          navigation.replace('GameEndStreak');
        } else {
          if (leaderBoardCache) {
            navigation.replace('GameEndLeaderboard');
          } else {
            navigation.replace('GameCompleted');
          }
        }
      }, 300);
    }
  }, [navigation, gameState]);

  useEffect(() => {
    loadScores();
  }, []);
  return (
    <SafeAreaView style={styles.backgroundStyle}>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={styles.backgroundStyle.backgroundColor}
      />
      <Board
        goBack={() => navigation.goBack()}
        openSettings={() => navigation.navigate('Settings')}
      />
      <View style={styles.spacer} />
      <BannerAd />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  spacer: {flex: 1},
  backgroundStyle: {
    backgroundColor: 'black',
    flex: 1,
  },
});
