import React, {useEffect} from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';

import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/types';
import {MiniBoard} from '../components/MiniBoard';
import {TimerText} from '../components/Timer';
import {useGameStore} from '../state/game';
import {formatDifficulty} from '../components/Board';
import {Button} from '../components/Button';
import {format, parse} from 'date-fns';
import {Analytics} from '../analytics';
import {getGameScore} from '../core/score';
import {GooglePlayGames, LeaderBoards} from '../native/GooglePlayGames';
import {GameCenter} from '../native/GameCenter';
import {Ads} from '../core/Ads';
import {useTotalScore} from '../hooks/useTotalScore';

let adCounter = 0;

export function GameCompletedScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'GameCompleted'>) {
  const backgroundStyle: ViewStyle = {
    backgroundColor: 'black',
    alignItems: 'center',
    flex: 1,
  };

  const board = useGameStore(state => state.board);
  const gameType = useGameStore(state => state.gameType);
  const dailyDate = useGameStore(state => state.dailyDate);

  const candidateNumbers = useGameStore(state => state.candidateNumbers);
  const difficulty = useGameStore(state => state.difficulty);
  const totalScore = useTotalScore();

  useEffect(() => {
    adCounter++;
    if (adCounter % 2 === 0 || totalScore > 1000) {
      Ads.showGameEndAd();
    }
  }, [totalScore]);
  return (
    <SafeAreaView style={backgroundStyle}>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={backgroundStyle.backgroundColor}
      />
      <ScrollView contentContainerStyle={styles.scrollContainerStyle}>
        <Text style={styles.title}>
          {gameType === 'daily' ? '🏆' : '🎉'} {'\n'}Congratulations!
        </Text>
        <MiniBoard
          scale={0.7}
          board={board}
          candidateNumbers={candidateNumbers}
        />
        <TimerText style={styles.timer} />
        <View style={styles.body}>
          {gameType === 'daily' && (
            <Text style={styles.dailyDate}>
              {format(parse(dailyDate + '', 'yyyyMMdd', new Date()), 'MMM dd')}
            </Text>
          )}

          <Text style={styles.difficulty}>{formatDifficulty(difficulty)}</Text>

          <Text style={styles.points}>
            {'You earned 👑 ' +
              getGameScore(difficulty, candidateNumbers.length) +
              ' points.'}
          </Text>
        </View>
        <Button
          variant="primary"
          onPress={() => {
            navigation.replace('DifficultyScreen');
            Analytics.logEvent('click_new_game_from_game_end');
          }}
          style={styles.newGameButton}>
          {'New Game'}
        </Button>

        <Button
          variant="secondary"
          onPress={() => {
            Analytics.logEvent('click_leaderboard_from_game_end');
            if (Platform.OS === 'android') {
              GooglePlayGames.openLeaderBoard(LeaderBoards.TOTAL_SCORE).catch(
                () => {
                  //  ToastAndroid.show('Failed to open leaderboard', 1000);
                },
              );
            }
            if (Platform.OS === 'ios') {
              GameCenter.openLeaderboard();
            }
          }}
          style={styles.leaderboardBUtton}>
          {'Leaderboard'}
        </Button>

        <Button
          variant="secondary"
          onPress={() => {
            navigation.replace('Tabs');
            Analytics.logEvent('click_home_from_game_end');
          }}
          style={styles.homeButton}>
          Home
        </Button>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  homeButton: {
    marginTop: 8,
    width: 210,
    paddingVertical: 0,
  },
  leaderboardBUtton: {
    width: 210,
    paddingVertical: 0,
    marginTop: 8,
  },
  newGameButton: {
    width: 210,
    paddingVertical: 0,
  },
  points: {
    color: 'white',
    alignSelf: 'center',
    marginTop: 12,
    fontSize: 18,
    textAlign: 'center',
  },
  difficulty: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 14,
    textAlign: 'center',
  },
  dailyDate: {
    color: 'white',
    alignSelf: 'center',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 8,
  },
  body: {
    marginBottom: 30,
  },
  timer: {fontSize: 32, marginTop: 16, marginBottom: 8},
  title: {
    color: 'white',
    alignSelf: 'center',
    marginTop: 40,
    marginBottom: 10,
    fontSize: 36,
    textAlign: 'center',
  },
  scrollContainerStyle: {alignItems: 'center'},
});
