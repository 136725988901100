import React, {useEffect, useRef} from 'react';
import {
  Animated,
  LogBox,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';

import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/types';
import {Text} from 'react-native';
import {Button} from '../components/Button';
import {Easing} from 'react-native-reanimated';
import {useStreakStatus} from '../hooks/useStreakStatus';
import {getStreakDays} from '../core/streak';

export function GameEndStreakScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'GameEndStreak'>) {
  const streakStatus = useStreakStatus();
  const streak = getStreakDays(streakStatus);

  const scaleRef = useRef<Animated.Value>(new Animated.Value(1));
  const translateY = useRef<Animated.Value>(new Animated.Value(0));
  const opacity = useRef<Animated.Value>(new Animated.Value(1));
  const fade = useRef<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(scaleRef.current, {
      toValue: 1.4,
      delay: 300,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      Animated.parallel([
        Animated.timing(translateY.current, {
          toValue: -40,
          delay: 300,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.timing(opacity.current, {
          toValue: 0,
          delay: 300,
          duration: 200,
          useNativeDriver: true,
        }),
      ]).start(() => {
        Animated.timing(scaleRef.current, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();
        Animated.timing(fade.current, {
          toValue: 1,
          duration: 800,
          easing: Easing.exp,
          useNativeDriver: true,
        }).start();
      });
    });
  }, [streak]);
  return (
    <SafeAreaView style={styles.backgroundStyle}>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={styles.backgroundStyle.backgroundColor}
      />
      <View style={{flex: 2, alignItems: 'center', justifyContent: 'center'}}>
        <View style={{flexDirection: 'row'}}>
          <Animated.Text
            style={{
              color: 'white',
              fontSize: 48,
              opacity: opacity.current,
              transform: [
                {scale: scaleRef.current},
                {translateY: translateY.current},
              ],
              fontVariant: ['tabular-nums'],
            }}>
            {streak - 1}
          </Animated.Text>
          <Animated.Text
            style={{
              color: 'white',
              fontSize: 48,
              position: 'absolute',
              opacity: opacity.current.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0],
              }),
              transform: [
                {scale: scaleRef.current},
                {
                  translateY: translateY.current.interpolate({
                    inputRange: [-40, 0],
                    outputRange: [0, 40],
                  }),
                },
              ],
              fontVariant: ['tabular-nums'],
            }}>
            {streak}
          </Animated.Text>
          <Text style={{color: 'white', fontSize: 48}}>🔥</Text>
        </View>

        <Text style={{color: 'white', fontSize: 36}}>day streak</Text>

        <Animated.Text
          style={{
            color: 'white',
            marginTop: 30,
            fontSize: 16,
            textAlign: 'center',
            opacity: fade.current,
          }}>
          Congratulations!{'\n'}
          {'\n'}Practice everyday to keep your mind sharp.
        </Animated.Text>
      </View>
      <Animated.View
        style={{
          opacity: fade.current,
          marginBottom: 80,
        }}>
        <Button
          variant="primary"
          onPress={() => {
            navigation.replace('GameCompleted');
          }}
          // style={{backgroundColor: 'orange', borderColor: 'white'}}
        >
          Continue
        </Button>
      </Animated.View>
    </SafeAreaView>
  );
}
LogBox.ignoreAllLogs();

const styles = StyleSheet.create({
  spacer: {flex: 1},
  backgroundStyle: {
    backgroundColor: 'black',
    flex: 1,
  },
});
