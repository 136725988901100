import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Alert, Platform, StyleSheet, Text, View} from 'react-native';
import {RootStackParamList} from '../navigation/types';
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
} from '@gorhom/bottom-sheet';
import {useStreakStatus} from '../hooks/useStreakStatus';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Button} from '../components/Button';
import {Ads} from '../core/Ads';
import {useMMKVNumber} from 'react-native-mmkv';
import {Storage, streakProtectionKey} from '../storage';
import {getStreakDays} from '../core/streak';

type ProtectionStatus = 'none' | 'equipped' | 'used';

export function StreakProtection({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'StreakProtection'>) {
  const streakStatus = useStreakStatus();
  const [protectionCountStore] = useMMKVNumber(streakProtectionKey);

  const {bottom} = useSafeAreaInsets();
  const [isAdLoading, setIsAdLoading] = useState(false);

  const streakDays = getStreakDays(streakStatus);

  const protectionCount = protectionCountStore || 0;
  let protectionStatus: ProtectionStatus = 'none';

  if (protectionCount && protectionCount > 0) {
    protectionStatus = 'equipped';
  }

  if (streakStatus?.status === 'restored') {
    protectionStatus = 'used';
  }
  console.log(streakStatus, protectionStatus);

  const snap = 380 + bottom + (Platform.OS === 'android' ? 10 : 0);
  // hooks
  const bottomSheetRef = useRef<BottomSheet>(null);

  // variables
  const snapPoints = useMemo(() => [snap, snap], [snap]);

  // callbacks
  const handleSheetChange = useCallback((index: number) => {
    console.log('handleSheetChange', index);
  }, []);

  // renders
  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        appearsOnIndex={1}
        pressBehavior="close"
      />
    ),
    [],
  );

  const getProtection = () => {
    setIsAdLoading(true);
    Ads.requestRewardedAd({
      onReward: () => {
        Storage.incrementStreakProtection();
      },
      onError: () => {
        Alert.alert('Error', 'Failed to load ad');
        setIsAdLoading(false);
      },
      onClose: () => {
        setIsAdLoading(false);
        setTimeout(() => {
          close();
        }, 1500);
      },
    });
  };

  const close = () => {
    bottomSheetRef?.current?.close({duration: 200});
  };

  const getText = () => {
    if (protectionStatus === 'used') {
      return (
        'Your ' +
        streakStatus?.numberOfDays +
        ' day streak is restored🛡️\n\nWatch an ad to get a streak protection in case you miss an another day.'
      );
    }

    if (protectionCount > 0) {
      return 'You have a streak protection which will protect your streak in case you miss a day.';
    }

    if (streakStatus?.status === 'broken') {
      return (
        'Your ' +
        streakStatus?.numberOfDays +
        ' day streak is broken 😔\n\n Watch an ad to get a streak protection in case you miss an another day.'
      );
    }

    return 'Watch an ad to get a free streak protection in case you miss a day.';
  };
  return (
    <BottomSheet
      ref={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={renderBackdrop}
      animateOnMount={true}
      enablePanDownToClose={true}
      onClose={() => {
        navigation.pop();
      }}
      backgroundStyle={styles.sheetBackground}
      handleIndicatorStyle={styles.indicator}
      onChange={handleSheetChange}>
      <View
        style={{
          flexDirection: 'column',
          height: '100%',
          marginHorizontal: 28,
          paddingBottom: bottom + 28,
        }}>
        <Text style={styles.title}>{'Streak'}</Text>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          <Text allowFontScaling={false} style={{color: 'white', fontSize: 96}}>
            {streakDays}
          </Text>
          <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <View>
              <Text
                allowFontScaling={false}
                style={{color: 'white', fontSize: 72}}>
                🔥
              </Text>
            </View>
          </View>
        </View>

        <Text
          allowFontScaling={false}
          style={{
            color: protectionCount > 0 ? 'lightgreen' : 'yellow',
            fontSize: 16,
            alignSelf: 'center',
            justifyContent: 'center',
          }}>
          {protectionCount > 0
            ? `${protectionCount} Streak Protection🛡️`
            : 'No Streak Protection🛡️'}
        </Text>

        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
          <Text
            allowFontScaling={false}
            style={{
              color: 'white',
              fontSize: 16,
              textAlign: 'center',
              maxWidth: 400,
            }}>
            {getText()}
          </Text>
        </View>
        {protectionStatus !== 'equipped' && (
          <Button
            variant="primary"
            loading={isAdLoading}
            onPress={() => {
              getProtection();
            }}>
            Get Streak Protection
          </Button>
        )}
      </View>
    </BottomSheet>
  );
}

const styles = StyleSheet.create({
  sheetBackground: {backgroundColor: 'rgb(20,20,20)'},
  indicator: {backgroundColor: 'transparent'},
  title: {
    color: 'white',
    paddingHorizontal: 24,
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
  seperator: {
    width: '100%',
    height: 1,
    backgroundColor: '#333333',
  },
  button: {
    backgroundColor: '#4169E1',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 4,
    width: 200,
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '500',
  },
});
