import React, {
  useEffect,
  useRef,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import {
  View,
  Animated,
  PanResponder,
  PanResponderGestureState,
  Text,
  GestureResponderEvent,
  LayoutRectangle,
  StyleSheet,
} from 'react-native';
import {CandidateNumber, useGameStore} from '../state/game';

type DraggableProps = PropsWithChildren & {
  item: CandidateNumber;
  tileSize?: number;
  boardSize: number;
  draggableSize?: number;
  largeFont?: boolean;
};

export function Draggable({
  item,
  tileSize = 30,
  boardSize = 11,
  draggableSize = 40,
  largeFont = false,
}: DraggableProps) {
  const layout = useGameStore(state => state.layout);
  const moveNumber = useGameStore(state => state.moveNumber);
  const canMoveItem = useGameStore(state => state.canMoveItem);
  const candidateNumbers = useGameStore(state => state.candidateNumbers);

  const scaleFactor = tileSize / draggableSize;

  const val = useRef<{
    x: number;
    y: number;
  }>(
    item.position > -1
      ? getSlotCoordinates(
          item.position,
          layout,
          tileSize,
          boardSize,
          draggableSize,
        )
      : {x: 0, y: 0},
  );
  const pan = useRef<Animated.ValueXY>(new Animated.ValueXY()).current;
  const scale = useAnimatedValue(0, {useNativeDriver: false});
  const [zIndex, setZIndex] = useState(1);

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          console.log('onPanResponderGrant', {setOffset: val.current});
          pan.setOffset({
            x: val.current?.x || 0,
            y:
              (val.current?.y || 0) -
              (item.position === -1 ? draggableSize * 1.25 : 0),
          });
          pan.setValue({x: 0, y: 0});
          setZIndex(999);
          Animated.timing(scale, {
            useNativeDriver: false,
            duration: 100,
            toValue: 1,
          }).start();
        },
        onPanResponderMove: (
          e: GestureResponderEvent,
          gestureState: PanResponderGestureState,
        ) => {
          return Animated.event([null, {dx: pan.x, dy: pan.y}], {
            useNativeDriver: false,
          })(e, gestureState);
        },
        onPanResponderRelease: (_e, _gesture) => {
          console.log('onPanResponderRelease', _gesture.moveX, _gesture.moveY);
          Animated.timing(pan, {
            toValue: {x: 0, y: item.position === -1 ? draggableSize * 1.25 : 0},
            duration: 100,
            useNativeDriver: false,
          }).start();

          // const slot = getSlotNumber(gesture.moveX, gesture.moveY, layout);

          const gesture = {
            ..._gesture,
            moveY:
              _gesture.moveY -
              (item.position === -1 ? draggableSize * 1.25 : 0),
          };

          const slot = getDropSlot(
            candidateNumbers,
            gesture.moveX,
            gesture.moveY,
            layout,
            tileSize,
            boardSize,
            draggableSize,
          );
          console.log('slot', slot);

          console.log('isDropArea', isDropArea(gesture, layout));
          if (
            slot > -1 &&
            gesture.moveX > 0 &&
            gesture.moveY > 0 &&
            isDropArea(gesture, layout) &&
            canMoveItem(item.id, slot)
          ) {
            moveNumber(item.id, slot);
            Animated.timing(scale, {
              useNativeDriver: false,
              duration: 100,
              toValue: scaleFactor,
            }).start();
          } else {
            if (item.position > -1) {
              Animated.timing(scale, {
                useNativeDriver: false,
                duration: 100,
                toValue: scaleFactor,
              }).start();
              moveNumber(item.id, -1);
            }
          }
          setZIndex(1);
        },
      }),
    [
      pan,
      layout,
      moveNumber,
      canMoveItem,
      candidateNumbers,
      item.position,
      item.id,
      scale,
      scaleFactor,
      tileSize,
      boardSize,
      draggableSize,
    ],
  );

  useEffect(() => {
    pan.addListener(value => (val.current = value));

    return () => {
      pan.removeAllListeners();
    };
  }, [pan]);

  useEffect(() => {
    // Scale animation
    Animated.sequence([
      /*       Animated.timing(scale, {
        useNativeDriver: false,
        duration: 50,
        toValue: 0,
      }), */
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: 1.2,
      }),
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: 1,
      }),
    ]).start();
  }, [scale]);

  useEffect(() => {
    // Replace animation
    if (item.position > -1) {
      pan.flattenOffset();
      Animated.timing(pan, {
        toValue: getSlotCoordinates(
          item.position,
          layout,
          tileSize,
          boardSize,
          draggableSize,
        ),
        duration: 100,
        useNativeDriver: false,
      }).start();
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: scaleFactor,
      }).start();
    } else {
      pan.flattenOffset();
      const candidateRowSize = candidateNumbers.length > 14 ? 7 : 6;
      Animated.timing(pan, {
        toValue: {
          x:
            20 +
            (item.id % candidateRowSize) *
              ((layout.width - 24) / candidateRowSize),
          y: 12 + Math.floor(item.id / candidateRowSize) * (draggableSize + 10),
        },
        delay: 100,
        duration: 100,
        useNativeDriver: false,
      }).start();
      Animated.timing(scale, {
        useNativeDriver: false,
        duration: 100,
        toValue: item.position > -1 ? scaleFactor : 1,
      }).start();
    }
  }, [
    pan,
    item.id,
    scale,
    item.position,
    layout,
    scaleFactor,
    tileSize,
    boardSize,
    draggableSize,
    candidateNumbers.length,
  ]);

  const panStyle = {
    transform: [...pan.getTranslateTransform(), {scale}],
  };
  return (
    <View style={[styles.draggableContainer, {zIndex}]}>
      <Animated.View {...panResponder.panHandlers} style={[panStyle]}>
        <View
          style={[
            styles.item,
            {width: draggableSize, height: draggableSize},
            zIndex === 999 && styles.highligtedItem,
            item.isWrong && styles.wrongItem,
          ]}>
          <Text
            allowFontScaling={false}
            selectable={false}
            style={[
              styles.value,
              {
                fontSize: largeFont ? draggableSize * 0.7 : draggableSize * 0.6,
              },
            ]}>
            {item.value}
          </Text>
        </View>
      </Animated.View>
    </View>
  );
}

export default function useAnimatedValue(
  initialValue: number,
  config?: any,
): Animated.Value {
  const ref = useRef<null | Animated.Value>(null);
  if (ref.current == null) {
    ref.current = new Animated.Value(initialValue, config);
  }
  return ref.current;
}

export function getSlotCoordinates(
  slot: number,
  layout: LayoutRectangle,
  tileSize: number,
  boardSize: number,
  draggableSize: number,
): {x: number; y: number} {
  const x = slot % boardSize;
  const y = Math.floor(slot / boardSize);
  const scaleFactor = tileSize / draggableSize;
  const yOffset = (1 - scaleFactor) * (draggableSize / 2);
  return {
    x: tileSize * x + 12,
    y: tileSize * y - layout.height + 4 - yOffset,
  };
}

function getDropSlot(
  candidateNumbers: CandidateNumber[],
  x: number,
  y: number,
  layout: LayoutRectangle,
  tileSize: number,
  boardSize: number,
  draggableSize: number,
) {
  const extraOffset = draggableSize / 4;
  const matchAreas = candidateNumbers.map(c => {
    const slot = c.correctPosition;
    const sx = slot % boardSize;
    const sy = Math.floor(slot / boardSize);
    const cord = {
      x: tileSize * sx + layout.x,
      y: tileSize * sy + layout.y,
    };
    return {
      position: c.correctPosition,
      coords: {
        x1: cord.x - extraOffset,
        x2: cord.x + draggableSize + extraOffset,
        y1: cord.y - extraOffset,
        y2: cord.y + draggableSize + extraOffset,
      },
    };
  });
  const slot = matchAreas.reduce((arr, a) => {
    const intersectResult = rectanglesIntersect(a.coords, {
      x1: x - extraOffset,
      x2: x + extraOffset,
      y1: y - extraOffset,
      y2: y + extraOffset,
    });
    if (intersectResult.intersect) {
      arr.push({position: a.position, distance: intersectResult.distance || 0});
    }
    return arr;
  }, [] as {position: number; distance: number}[]);

  if (slot.length > 0) {
    let closestMatch = slot[0];
    slot.forEach(s => {
      if (s.distance < closestMatch.distance) {
        closestMatch = s;
      }
    });
    return closestMatch.position;
  }
  return -1;
}

function rectanglesIntersect(
  a: {x1: number; y1: number; x2: number; y2: number},
  b: {x1: number; y1: number; x2: number; y2: number},
): {intersect: boolean; distance?: number} {
  console.log(a, b);
  const aLeftOfB = a.x2 < b.x1;
  const aRightOfB = a.x1 > b.x2;
  const aAboveB = a.y1 > b.y2;
  const aBelowB = a.y2 < b.y1;

  const intersect = !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
  if (!intersect) {
    return {intersect: false};
  }

  const centerA = {x: (a.x2 + a.x1) / 2, y: (a.y2 + a.y1) / 2};
  const centerB = {x: (b.x2 + b.x1) / 2, y: (b.y2 + b.y1) / 2};

  const distanceX = centerA.x - centerB.x;
  const distanceY = centerA.y - centerB.y;
  var distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

  return {intersect, distance};
}

/*function getSlotNumber(x: number, y: number, layout: LayoutRectangle): number {
  console.log({x, layoutX: layout.x});
  console.log({y, layoutY: layout.y});

  const xIndex = Math.round((x - layout.x) / 30) - 1;
  const yIndex = Math.round((y - layout.y - 30) / 30);
  console.log(xIndex, yIndex);
  const index = yIndex * 11 + xIndex;
  if (index < 0) {
    return -1;
  }
  if (index >= 121) {
    return -1;
  }
  return index;
}
*/
const isDropArea = (
  gesture: PanResponderGestureState,
  layout: LayoutRectangle,
): boolean => {
  console.log(gesture, layout);
  return (
    gesture.moveY > layout.y &&
    gesture.moveY < layout.y + layout.height &&
    gesture.moveX > layout.x &&
    gesture.moveX < layout.x + layout.width
  );
};

const styles = StyleSheet.create({
  wrongItem: {backgroundColor: 'lightpink'},
  draggableContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
  },
  highligtedItem: {
    borderWidth: 4,
    borderColor: 'cyan',
  },
  item: {
    backgroundColor: '#e3f6f6',
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#604b30',
    borderRadius: 8,
  },
  value: {
    color: '#109734',
    fontSize: 23,
    fontWeight: 'bold',
  },
});
