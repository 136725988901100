import {differenceInDays, parseISO} from 'date-fns';
import {Storage} from '../storage';

export type StreakStatus = {
  numberOfDays: number;
  lastPlayedAt: Date | string | undefined;
  restoredAt: Date | string | undefined;
  status: 'inactive' | 'active' | 'broken' | 'restored';
};

export function getStreakStatus() {
  const saved = Storage.getStreakStatus();

  let streakStatus: StreakStatus = {
    numberOfDays: 0,
    lastPlayedAt: undefined,
    restoredAt: undefined,
    status: 'inactive',
  };
  if (saved) {
    try {
      streakStatus = JSON.parse(saved) as StreakStatus;
    } catch (err) {}
  }
  if (streakStatus.status === 'active' && streakStatus.lastPlayedAt) {
    const diff = differenceInDays(
      new Date(),
      typeof streakStatus.lastPlayedAt === 'string'
        ? parseISO(streakStatus.lastPlayedAt)
        : streakStatus.lastPlayedAt,
    );
    const hasProtection = Storage.getStreakProtection() > 0;

    if (diff === 2 && hasProtection) {
      streakStatus.status = 'restored';
      streakStatus.restoredAt = new Date();
      Storage.setStreakStatus(JSON.stringify(streakStatus));
      Storage.decrementStreakProtection();
    } else if (diff > 1) {
      // streak is broken
      streakStatus.status = 'broken';
      Storage.setStreakStatus(JSON.stringify(streakStatus));
    }
  } else if (streakStatus.status === 'restored' && streakStatus.restoredAt) {
    const restoredDiff = differenceInDays(
      new Date(),
      typeof streakStatus.restoredAt === 'string'
        ? parseISO(streakStatus.restoredAt)
        : streakStatus.restoredAt,
    );
    const hasProtection = Storage.getStreakProtection() > 0;
    if (restoredDiff === 2 && hasProtection) {
      streakStatus.status = 'restored';
      streakStatus.restoredAt = new Date();
      Storage.setStreakStatus(JSON.stringify(streakStatus));
      Storage.decrementStreakProtection();
    } else if (restoredDiff > 1) {
      // streak is broken
      streakStatus.status = 'broken';
      Storage.setStreakStatus(JSON.stringify(streakStatus));
    }
  }
  return streakStatus;
}

export function addStreak() {
  const streakStatus = getStreakStatus();
  console.log('addStreak', streakStatus);
  if (streakStatus.status === 'active' || streakStatus.status === 'restored') {
    streakStatus.numberOfDays = streakStatus.numberOfDays + 1;
    streakStatus.lastPlayedAt = new Date();
    streakStatus.status = 'active';
    Storage.setStreakStatus(JSON.stringify(streakStatus));
  } else if (
    streakStatus.status === 'broken' ||
    streakStatus.status === 'inactive'
  ) {
    streakStatus.status = 'active';
    streakStatus.numberOfDays = 1;
    streakStatus.lastPlayedAt = new Date();
  }
  Storage.setStreakStatus(JSON.stringify(streakStatus));
}

export function getStreakDays(streakStatus: StreakStatus | undefined) {
  return streakStatus?.status === 'active' ||
    streakStatus?.status === 'restored'
    ? streakStatus.numberOfDays
    : 0;
}
