import {easyMaps, extremeMaps, hardMaps, mediumMaps} from './presetMaps';

const operations = ['+', '-', 'x', '÷'];

export type OperationStatus = 'incomplete' | 'correct' | 'incorrect';

export type BoardOperation = {x: number; y: number; direction: 'left' | 'down'};

export type Difficulty = 'easy' | 'medium' | 'hard' | 'extreme';

export type Board = (string | number)[][];
function emptyBoard(size: number) {
  const board: Board = [
    /*  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], */
  ];
  for (let i = 0; i < size; i++) {
    const row = Array.from({length: size}).map(() => 0);
    board.push(row);
  }
  return board;
}

export function generateBoard(
  map: BoardOperation[],
  difficulty: Difficulty,
  boardSize: number,
) {
  const board = emptyBoard(boardSize);

  map.forEach(o => {
    if (o.direction === 'left') {
      generateLeft(board, o.x, o.y, difficulty);
    } else if (o.direction === 'down') {
      generateDown(board, o.x, o.y, difficulty);
    }
  });

  /*   generateLeft(board, 3, 0);
  generateDown(board, 5, 0);
  generateLeft(board, 2, 3);
  generateDown(board, 3, 2);
  generateLeft(board, 3, 6);
  generateLeft(board, 3, 8);
  generateDown(board, 5, 6); */
  return board;
}

export function createCandidates(map: BoardOperation[]) {
  const selectedPosition: number[][] = [];
  const positionPool: number[][] = [];
  map.forEach(({x, y, direction}) => {
    const p = [];
    p.push([x, y]);
    if (direction === 'down') {
      p.push([x, y + 2]);
      p.push([x, y + 4]);
    } else if (direction === 'left') {
      p.push([x + 2, y]);
      p.push([x + 4, y]);
    }
    const randomItem = randomInt(3);
    selectedPosition.push(p[randomItem]);
    p.splice(randomItem, 1);
    positionPool.push(...p);
  });
  const randomItem = randomInt(positionPool.length);
  selectedPosition.push(positionPool[randomItem]);
  positionPool.splice(randomItem, 1);

  const uniquePositions = unique(selectedPosition);
  return uniquePositions.slice(0, Math.min(uniquePositions.length, 17));
}

function createCandidatesForDifficulty(
  map: BoardOperation[],
  difficulty: Difficulty,
) {
  switch (difficulty) {
    case 'easy':
      return createCandidates(map);
    case 'medium':
      return createCandidates(map);
    case 'hard':
      return createHardCandidates(map, 20);
    case 'extreme':
      return createHardCandidates(map, 26);
  }
}

export function createHardCandidates(map: BoardOperation[], maxAmount: number) {
  const first = createCandidates(map);
  const second = createCandidates(map);
  const uniquePositions = unique([...first, ...second]);
  return uniquePositions.slice(0, Math.min(uniquePositions.length, maxAmount));
}

function unique(items: number[][]) {
  var d: {[key: string]: boolean} = {};
  var out = [];
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var rep = item.toString();

    if (!d[rep]) {
      d[rep] = true;
      out.push(item);
    }
  }
  return out;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateMap() {
  const map: BoardOperation[] = [];
  const startingPoint = randomInt(5);
  map.push({x: startingPoint + 1, y: 0, direction: 'left'});

  for (let i = 0; i < 5; i++) {
    const last = map[map.length - 1];
    const positions = [2, 4];

    const offset = positions[randomInt(positions.length)];
    if (last.direction === 'left') {
      map.push({
        x: last.x + offset,
        y: last.y > 6 ? last.y - 2 : last.y,
        direction: 'down',
      });
    } else if (last.direction === 'down') {
      map.push({
        x: last.x > 6 ? last.x - 2 : last.x,
        y: last.y + offset,
        direction: 'left',
      });
    }
  }
  console.log(JSON.stringify(map, null, 2));
  return map;
}

function getMap(difficulty: Difficulty) {
  let boardSize = 11;
  let map = easyMaps[randomInt(easyMaps.length)];
  if (difficulty === 'medium') {
    map = mediumMaps[randomInt(mediumMaps.length)];
  }
  if (difficulty === 'hard') {
    map = hardMaps[randomInt(hardMaps.length)];
  }
  if (difficulty === 'extreme') {
    const index = randomInt(extremeMaps.length);
    map = extremeMaps[index];
    boardSize = index < 1 ? 13 : 11;
  }
  return {map, boardSize};
}

export function puzzle(difficulty: Difficulty) {
  let {map, boardSize} = getMap(difficulty);
  // const boardSize = difficulty === 'extreme' ? 13 : 11;

  if (randomInt(2) === 1) {
    map = rotateMap(map);
  }
  let board: Board | undefined;
  let retryCount = 0;

  do {
    try {
      board = generateBoard(map, difficulty, boardSize);
    } catch (err) {
      console.warn('Failed to create board', err);
    }
    retryCount++;
  } while (!board || (!checkBoard(board, map) && retryCount < 5));

  if (!board || (retryCount >= 5 && !checkBoard(board, map))) {
    return puzzle(difficulty);
  }

  const coordinates = createCandidatesForDifficulty(map, difficulty);

  const candidateNumbers: {value: number; position: number}[] = [];

  coordinates.forEach(c => {
    console.log(c);
    const val = board![c[0]][c[1]];
    board![c[0]][c[1]] = 'e';
    candidateNumbers.push({
      value: val as number,
      position: getPosition(c[0], c[1], boardSize),
    });
  });

  return {board, candidateNumbers, map, difficulty};
}

export function rotateMap(map: BoardOperation[]) {
  const rotatedMap: BoardOperation[] = [];
  map.forEach(m => {
    rotatedMap.push({
      x: m.y,
      y: m.x,
      direction: m.direction === 'down' ? 'left' : 'down',
    });
  });
  return rotatedMap;
}

export function getPosition(x: number, y: number, boardSize: number) {
  return y * boardSize + x;
}

function generateLeft(
  board: (string | number)[][],
  x: number,
  y: number,
  difficulty: Difficulty,
) {
  const left = parseInt(board[x][y] + '', 10);
  const operand = board[x + 1][y] !== 0 ? String(board[x + 1][y]) : undefined;
  const right = parseInt(board[x + 2][y] + '', 10);
  const result = parseInt(board[x + 4][y] + '', 10);

  const op = createOperation({left, operand, right, result, difficulty});
  board[x][y] = op.left;
  board[x + 1][y] = op.operand;
  board[x + 2][y] = op.right;
  board[x + 3][y] = '=';
  board[x + 4][y] = op.result;
}

function generateDown(
  board: (string | number)[][],
  x: number,
  y: number,
  difficulty: Difficulty,
) {
  const left = parseInt(board[x][y] + '', 10);
  const operand = board[x][y + 1] !== 0 ? String(board[x][y + 1]) : undefined;
  const right = parseInt(board[x][y + 2] + '', 10);
  const result = parseInt(board[x][y + 4] + '', 10);

  const op = createOperation({left, operand, right, result, difficulty});
  board[x][y] = op.left;
  board[x][y + 1] = op.operand;
  board[x][y + 2] = op.right;
  board[x][y + 3] = '=';
  board[x][y + 4] = op.result;
}

export function randomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function createOperationForGivenResult(
  options: {
    left?: number;
    operand?: string;
    right?: number;
    result?: number;
  },
  left: number,
  operand: string,
  right: number,
) {
  const result = options.result!;
  console.log('result is defined', options, operand);
  if (options!.right) {
    left = getOperationResult({
      left: result,
      right,
      operand: inverseOperand(operand)!,
    });
    if (left <= 0) {
      console.log('left was minus', left, operand, right, result);
      left =
        getOperationResult({
          left: result,
          right: left,
          operand: operand,
        }) * -1;
      operand = inverseOperand(operand)!;
    }
    console.log('right is defined', left, operand, right, result);
  } else {
    // result 9 - 25 + 16
    right =
      getOperationResult({
        left: result,
        right: left,
        operand: inverseOperand(operand)!,
      }) * (operand === '-' ? -1 : 1);

    if (right <= 0) {
      console.log('right was minus', left, operand, right, result);
      if (
        getOperationResult({
          left,
          right: right * -1,
          operand: inverseOperand(operand)!,
        }) === result
      ) {
        operand = inverseOperand(operand)!;
        right = right * -1;
      } else {
        right =
          getOperationResult({
            left: result,
            right: left,
            operand: operand,
          }) * (operand === '-' ? -1 : 1);
        //    operand = inverseOperand(operand)!;
      }
    }
    console.log('right is not defined', left, operand, right, result);
  }
  return {left, right, operand};
}

function getRandomOperation(difficulty: Difficulty) {
  if (difficulty === 'easy') {
    return randomInt(2);
  }
  if (difficulty === 'medium') {
    return randomInt(3);
  }
  return randomInt(4);
}

export function getRandomDiffuculty(): Difficulty {
  const number = randomInt(5);
  if (number <= 1) {
    return 'easy';
  } else if (number <= 3) {
    return 'medium';
  } else {
    return 'hard';
  }
}

function createOperation(
  options: {
    left?: number;
    operand?: string;
    right?: number;
    result?: number;
    difficulty: Difficulty;
  },
  retryCount = 0,
) {
  let left = options.left || randomInt(29) + 1;
  const operandRandom = getRandomOperation(options.difficulty);
  let operand = options.operand || operations[operandRandom];
  let right = options.right || randomInt(20) + 1;

  let result = options.result;

  if (options.result && options.left && options.right) {
    throw Error('Numbers are already defined!');
  }
  if (result) {
    const res = createOperationForGivenResult(options, left, operand, right);
    left = res.left;
    operand = res.operand;
    right = res.right;
  } else {
    const isResultWillBeNegative =
      options.left &&
      options.right &&
      options.left <= options.right &&
      operand === '-';
    if (isResultWillBeNegative) {
      // prevent minus results
      operand = '+';
    }
    if (left === 1 && operand === '-') {
      return createOperation(options);
    }

    if (operand === '-') {
      // prevent minus results
      right = options.right || randomInt(left - 1) + 1;
    }

    result = getOperationResult({left, right, operand});
  }

  const isValid =
    qualityCheck(left) && qualityCheck(right) && qualityCheck(result);
  if (!isValid) {
    if (retryCount >= 5) {
      console.log('cant', left, right, result);
      throw Error("Can't generate this board!");
    }
    // try again
    return createOperation(options, retryCount + 1);
  }

  return {left, right, operand, result};
}

function qualityCheck(value: number) {
  if (!Number.isInteger(value)) {
    return false;
  }
  if (value > 99 || value < 1) {
    return false;
  }
  return true;
}

export function getOperationCoordinates({x, y, direction}: BoardOperation) {
  const leftCoordinates = {x, y};
  let operandCoordinates = {x, y};
  let rightCoordinates = {x, y};
  let resultCoordinates = {x, y};

  if (direction === 'down') {
    operandCoordinates = {x, y: y + 1};
    rightCoordinates = {x, y: y + 2};
    resultCoordinates = {x, y: y + 4};
  }

  if (direction === 'left') {
    operandCoordinates = {x: x + 1, y};
    rightCoordinates = {x: x + 2, y};
    resultCoordinates = {x: x + 4, y};
  }
  return {
    leftCoordinates,
    operandCoordinates,
    rightCoordinates,
    resultCoordinates,
  };
}

export function checkOperation({
  left,
  right,
  operand,
  result,
}: {
  left: number | string;
  right: number | string;
  operand: string;
  result: number | string;
}): OperationStatus {
  if (
    typeof left === 'string' ||
    typeof right === 'string' ||
    typeof result === 'string'
  ) {
    return 'incomplete';
  }

  if (result <= 0) {
    throw new Error(`Result should be always positive, but it is ${result}`);
  }

  const calculatedResult = getOperationResult({left, right, operand});
  if (calculatedResult !== result) {
    console.log(
      `Result is not correct ${left} ${operand} ${right} is equal to ${calculatedResult}`,
    );
    return 'incorrect';
  } else {
    return 'correct';
  }
}

export function getOperationResult({
  left,
  right,
  operand,
}: {
  left: number;
  right: number;
  operand: string;
}) {
  if (operand === '+') {
    return left + right;
  } else if (operand === '-') {
    return left - right;
  } else if (operand === 'x') {
    return left * right;
  } else if (operand === '÷') {
    return left / right;
  }
  return -1;
}

export function inverseOperand(operand: string) {
  if (operand === '+') {
    return '-';
  } else if (operand === '-') {
    return '+';
  } else if (operand === 'x') {
    return '÷';
  } else if (operand === '÷') {
    return 'x';
  }
  return undefined;
}

export function checkBoard(board: Board, map: BoardOperation[]): boolean {
  try {
    map.forEach(({x, y, direction}) => {
      const {
        leftCoordinates,
        operandCoordinates,
        rightCoordinates,
        resultCoordinates,
      } = getOperationCoordinates({x, y, direction});

      const left = board[leftCoordinates.x][leftCoordinates.y];
      const operand = String(board[operandCoordinates.x][operandCoordinates.y]);
      const right = board[rightCoordinates.x][rightCoordinates.y];
      const result = board[resultCoordinates.x][resultCoordinates.y];

      if (typeof left === 'number' && left < 0) {
        throw new Error(
          `left is negative ${left}${operand}${right}=${result} `,
        );
      }
      if (typeof right === 'number' && right < 0) {
        throw new Error(
          `right is negative ${left}${operand}${right}=${result} `,
        );
      }
      if (
        (typeof result === 'number' && result <= 0) ||
        typeof result === 'string'
      ) {
        throw new Error(`result is negative ${result}`);
      }
      const operationResult = checkOperation({left, operand, right, result});
      if (operationResult === 'incomplete') {
        throw new Error(
          `left: ${left} / right: ${right} / result:  ${result} one of them is string`,
        );
      }
      if (operationResult === 'incorrect') {
        throw new Error(
          `Result is not correct ${left} ${operand} ${right} is not equal to ${result}`,
        );
      }
    });
  } catch (error) {
    console.log(error);
    return false;
  }
  return true;
}
