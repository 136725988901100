import {NativeModules} from 'react-native';
import {Storage} from '../storage';
import {leaderBoardCache, setLeaderboardCache} from '../core/leaderboard';

type GameCenter = {
  signIn: () => Promise<void>;
  openDashboard: () => Promise<void>;
  openAchievements: () => void;
  openLeaderboard: () => void;
  submitScore: (score: number) => void;
  reportAchievement: (id: string, score: number) => void;
  /*  isAuthenticated: () => void;
  openLeaderBoard: (id: string) => Promise<void>;
  showAchievements: () => Promise<void>;
  incrementAchievement: (id: Achivements, value: number) => Promise<void>;
  submitScore: (id: LeaderBoards, score: number) => void;
  unlockAchievement: (id: Achivements) => Promise<void>; */
  loadLeaderboardScores: (id: string, noCache?: boolean) => Promise<any[]>;
};

export enum Achivements {
  PLAY_1_GAME = 'finisher', // Finisher
  PLAY_5_EASY_GAMES = 'easy_peasy', // Easy Peasy
  PLAY_10_MEDIUM_GAMES = 'medium_rare', // Medium Rare
  PLAY_15_HARD_GAMES = 'thats_hard', // That's Hard
  DAILY_7 = 'daily_7',
  DAILY_14 = 'daily_14',
  DAILY_30 = 'daily_30',
  DAILY_60 = 'daily_60',
  DAILY_90 = 'daily_90',
  DAILY_180 = 'daily_180',
  DAILY_365 = 'daily_365',
}
/* export enum LeaderBoards {
  TOTAL_SCORE = 'CgkI77_axIkUEAIQAQ',
}
 */
type LeaderboardEntry = {alias: string; rank: number; score: number};

type LeaderboardScores = {
  localUser: LeaderboardEntry;
  scores: LeaderboardEntry[];
};

export const GameCenter: GameCenter = {
  ...NativeModules.GameCenter,
  loadLeaderboardScores: (
    id: string,
    noCache = false,
  ): Promise<LeaderboardScores> => {
    if (leaderBoardCache && !noCache) {
      return Promise.resolve(leaderBoardCache);
    }
    return NativeModules.GameCenter.loadLeaderboardScores(id).then(
      (res: any) => {
        setLeaderboardCache(res);
        if (res.localUser.score > Storage.getTotalScore()) {
          Storage.setTotalScore(res.localUser.score);
        }
        return Promise.resolve(res);
      },
    );
  },
};
