import {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import {GooglePlayGames, LeaderBoards} from '../native/GooglePlayGames';
import VectorImage from './VectorImage';
import {Analytics} from '../analytics';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {RootStackParamList} from '../navigation/types';

export function AchievementsHeader() {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [achievementsLoading, setAchievementsLoading] = useState(false);
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'android') {
      GooglePlayGames?.init();
      GooglePlayGames?.signIn().then(console.log).catch(console.log);
    }
  }, []);

  return (
    <View style={styles.container}>
      <Pressable
        style={({pressed}) => ({
          paddingHorizontal: 8,
          opacity: pressed ? 0.8 : 1,
        })}
        onPress={() => {
          navigation.navigate('Settings');
        }}>
        <VectorImage
          style={styles.icon}
          source={require('../assets/settings-outline.svg')}
        />
      </Pressable>
      <Pressable
        disabled={leaderboardLoading}
        style={({pressed}) => ({
          paddingHorizontal: 8,
          opacity: pressed ? 0.8 : 1,
        })}
        onPress={() => {
          setLeaderboardLoading(true);
          Analytics.logEvent('click_leaderboard');

          GooglePlayGames.openLeaderBoard(LeaderBoards.TOTAL_SCORE)
            .catch(() => {
              //  ToastAndroid.show('Failed to open leaderboard', 1000);
              Analytics.logEvent('open_leaderboard_failed');
            })
            .finally(() => {
              setLeaderboardLoading(false);
            });
        }}>
        {leaderboardLoading ? (
          <ActivityIndicator style={styles.activityIndicator} />
        ) : (
          <VectorImage
            style={styles.icon}
            source={require('../assets/leaderboard.svg')}
          />
        )}
      </Pressable>
      <Pressable
        disabled={achievementsLoading}
        style={({pressed}) => ({
          paddingHorizontal: 8,
          opacity: pressed ? 0.8 : 1,
        })}
        onPress={() => {
          setAchievementsLoading(true);
          Analytics.logEvent('click_achievements');
          GooglePlayGames.showAchievements()
            .catch(() => {
              //  ToastAndroid.show('Failed to open achievements', 1000);
              Analytics.logEvent('open_achievements_failed');
            })
            .finally(() => {
              setAchievementsLoading(false);
            });
        }}>
        {achievementsLoading ? (
          <ActivityIndicator style={styles.activityIndicator} />
        ) : (
          <VectorImage
            style={styles.icon}
            source={require('../assets/trophy.svg')}
          />
        )}
      </Pressable>

      {/*   <View style={{flex: 1}} />
          <Pressable
            style={({pressed}) => ({
              paddingHorizontal: 8,
              opacity: pressed ? 0.8 : 1,
              marginLeft: 12,
            })}
            onPress={() => {
              GooglePlayGames?.signIn().then(console.log).catch(console.log);
            }}>
            <Image
              style={{height: 32}}
              resizeMode="contain"
              source={require('../assets/gplay_games.png')}
            />
          </Pressable> */}
    </View>
  );
}

const styles = StyleSheet.create({
  activityIndicator: {width: 32, height: 32},
  icon: {
    width: 32,
    height: 32,
    tintColor: 'white',
  },
  container: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    right: 12,
    top: 12,
    flexDirection: 'row-reverse',
  },
});
