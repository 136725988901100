import {Platform} from 'react-native';
import {Storage} from '../storage';
import {Difficulty} from './generator';
import {GooglePlayGames, LeaderBoards} from '../native/GooglePlayGames';
import {GameCenter} from '../native/GameCenter';

export function getGameScore(difficulty: Difficulty, candidates: number) {
  const multipliers: {[key in Difficulty]: number} = {
    easy: 1,
    medium: 2,
    hard: 3,
    extreme: 4,
  };
  return multipliers[difficulty] * candidates * 10;
}

export function addScore(score: number) {
  const newScore = Storage.getTotalScore() + score;
  Storage.setTotalScore(newScore);
  if (Platform.OS === 'android') {
    GooglePlayGames.submitScore(LeaderBoards.TOTAL_SCORE, newScore);
  } else if (Platform.OS === 'ios') {
    GameCenter.submitScore(newScore);
  }
}
