import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {CandidateNumber} from '../state/game';

export function DraggableTile({
  item,
  dragging,
  style,
  textStyle,
  tileSize,
}: {
  item: CandidateNumber & {value: number | string};
  dragging?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  tileSize: number;
}) {
  return (
    <View
      style={[
        styles.item,
        dragging && styles.highligtedItem,
        item.isWrong && styles.wrongItem,
        style,
        {width: tileSize, height: tileSize},
      ]}>
      <Text style={[styles.value, textStyle]}>{item.value || null}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrongItem: {backgroundColor: 'lightpink'},
  draggableContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
  },
  highligtedItem: {
    borderWidth: 4,
    borderColor: 'cyan',
  },
  item: {
    backgroundColor: '#e3f6f6',
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#604b30',
    borderRadius: 8,
  },
  value: {
    color: '#109734',
    fontSize: 23,
    fontWeight: 'bold',
  },
});
