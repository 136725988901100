import {Storage} from '../storage';
import {Difficulty} from './generator';

export type ClassicGameStat = {
  difficulty: Difficulty;
  gameStarted: number;
  gameCompleted: number;
};
export type ClassicGameStats = {[key in Difficulty]: ClassicGameStat};

const DEFAULT_STATS: ClassicGameStats = {
  easy: {difficulty: 'easy', gameStarted: 0, gameCompleted: 0},
  medium: {difficulty: 'medium', gameStarted: 0, gameCompleted: 0},
  hard: {difficulty: 'hard', gameStarted: 0, gameCompleted: 0},
  extreme: {difficulty: 'extreme', gameStarted: 0, gameCompleted: 0},
};

export function addGameStartedStat(difficulty: Difficulty) {
  const stats = getGameStats();
  stats[difficulty].gameStarted = stats[difficulty].gameStarted + 1;
  saveGameStats(stats);
}

export function addGameCompletedStat(difficulty: Difficulty) {
  const stats = getGameStats();
  stats[difficulty].gameCompleted = stats[difficulty].gameCompleted + 1;
  saveGameStats(stats);
}

export function getGameStats() {
  const savedData = Storage.getGameStats();
  const stats = JSON.parse(savedData || '{}') as ClassicGameStats;
  return {...DEFAULT_STATS, ...stats};
}
export function saveGameStats(stats: ClassicGameStats) {
  Storage.setGameStats(JSON.stringify(stats));
}
