import {Platform} from 'react-native';
import {
  Achivements as GooglePlayGamesAchivements,
  GooglePlayGames,
} from '../native/GooglePlayGames';
import {
  GameCenter,
  Achivements as GameCenterAchivements,
} from '../native/GameCenter';
import {Storage} from '../storage';
import {Difficulty} from './generator';
import {getGameStats} from './stats';

class AchievementsClass {
  submitGameCompleted(difficulty: Difficulty) {
    if (Platform.OS === 'android') {
      GooglePlayGames.unlockAchievement(GooglePlayGamesAchivements.PLAY_1_GAME);
      if (difficulty === 'easy') {
        GooglePlayGames.incrementAchievement(
          GooglePlayGamesAchivements.PLAY_5_EASY_GAMES,
          1,
        );
      } else if (difficulty === 'medium') {
        GooglePlayGames.incrementAchievement(
          GooglePlayGamesAchivements.PLAY_10_MEDIUM_GAMES,
          1,
        );
      } else if (difficulty === 'hard') {
        GooglePlayGames.incrementAchievement(
          GooglePlayGamesAchivements.PLAY_15_HARD_GAMES,
          1,
        );
      }
    } else if (Platform.OS === 'ios') {
      GameCenter.reportAchievement(GameCenterAchivements.PLAY_1_GAME, 100);
      const stats = getGameStats();
      if (Platform.OS === 'ios') {
        if (difficulty === 'easy') {
          const percentage = (stats[difficulty].gameCompleted / 5) * 100;
          GameCenter.reportAchievement(
            GameCenterAchivements.PLAY_5_EASY_GAMES,
            percentage,
          );
        } else if (difficulty === 'medium') {
          const percentage = (stats[difficulty].gameCompleted / 10) * 100;
          GameCenter.reportAchievement(
            GameCenterAchivements.PLAY_10_MEDIUM_GAMES,
            percentage,
          );
        } else if (difficulty === 'hard') {
          const percentage = (stats[difficulty].gameCompleted / 10) * 100;
          GameCenter.reportAchievement(
            GameCenterAchivements.PLAY_15_HARD_GAMES,
            percentage,
          );
        }
      }
    }
  }

  submitDailyGameCompleted() {
    if (Platform.OS === 'android') {
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_7,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_14,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_30,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_60,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_90,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_180,
        1,
      );
      GooglePlayGames.incrementAchievement(
        GooglePlayGamesAchivements.DAILY_365,
        1,
      );
    } else if (Platform.OS === 'ios') {
      const dailyStatuses = Storage.getDailyStatuses();
      const completedCount =
        Object.values(dailyStatuses).filter(s => s === 'completed')?.length ||
        0;
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_7,
        (completedCount / 7) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_14,
        (completedCount / 14) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_30,
        (completedCount / 30) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_60,
        (completedCount / 60) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_90,
        (completedCount / 90) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_90,
        (completedCount / 90) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_180,
        (completedCount / 180) * 100,
      );
      GameCenter.reportAchievement(
        GameCenterAchivements.DAILY_365,
        (completedCount / 365) * 100,
      );
    }
  }
}

export const Achivements = new AchievementsClass();
