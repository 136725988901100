import {Audio} from 'expo-av';
class AudioPlayerImpl {
  pop: Audio.SoundObject | undefined;
  levelPassed: Audio.SoundObject | undefined;
  isSoundEffectsEnabled = true;
  async init() {
    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      staysActiveInBackground: false,
    });

    this.pop = await Audio.Sound.createAsync(
      require('../assets/sounds/pop.mp3'),
    );
    this.levelPassed = await Audio.Sound.createAsync(
      require('../assets/sounds/level-passed.mp3'),
    );
  }

  playPop() {
    if (!this.isSoundEffectsEnabled) {
      return;
    }
    this.pop!.sound.replayAsync().catch(() => {
      console.log('failed to play!');
    });
  }

  playLevelPassed() {
    if (!this.isSoundEffectsEnabled) {
      return;
    }
    this.levelPassed!.sound.replayAsync().catch(() => {
      console.log('failed to play!');
    });
  }
}

export const AudioPlayer = new AudioPlayerImpl();
