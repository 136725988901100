import {useEffect, useMemo} from 'react';
import {useGameStore} from '../state/game';
import {StyleSheet, Text, TextStyle} from 'react-native';

export function Timer() {
  const incrementGameDuration = useGameStore(s => s.incrementGameDuration);

  const timer = useMemo(() => {
    return setInterval(() => {
      incrementGameDuration();
    }, 1000);
  }, [incrementGameDuration]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [timer]);

  return <TimerText />;
}

export function TimerText({style}: {style?: TextStyle}) {
  const time = useGameStore(s => s.gameDurationInSeconds);
  return (
    <Text maxFontSizeMultiplier={2} style={[styles.timer, style]}>
      {formatDuration(time)}
    </Text>
  );
}

export function formatDuration(time: number) {
  const mins = Math.floor(time / 60);
  const seconds = time - mins * 60;
  const formattedTime = `${mins < 10 ? '0' + mins : mins}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
  return formattedTime;
}

const styles = StyleSheet.create({
  timer: {color: 'white', fontVariant: ['tabular-nums']},
});
