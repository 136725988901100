import {format} from 'date-fns';
import {GameType} from '../state/game';

export function isTodaysDailyGame(
  dailyDate: number | undefined,
  gameType: GameType,
) {
  if (!dailyDate || gameType !== 'daily') {
    return;
  }
  const today = parseInt(format(new Date(), 'yyyyMMdd'), 10);
  if (today === dailyDate) {
    return true;
  }
  return false;
}
