import {StyleSheet, View} from 'react-native';
import {Tile} from './Tile';
import {CandidateNumber} from '../state/game';
import {Board, getPosition} from '../core/generator';
import {DraggableTile} from './DraggableTile';

export function MiniBoard({
  board,
  candidateNumbers,
  hideItems,
  scale = 0.5,
}: {
  board: Board;
  candidateNumbers: CandidateNumber[];
  hideItems?: boolean;
  scale?: 0.5 | 0.7;
}) {
  const boardSize = board.length;
  const tileSize = boardSize === 13 ? 28 : 30;
  return (
    <View
      style={[
        styles.container,
        {
          height: (boardSize * tileSize + 40) * scale,
          width: (boardSize * tileSize + 40) * scale,
        },
      ]}>
      <View
        style={[
          styles.board,
          {width: boardSize * tileSize + 40, height: boardSize * tileSize + 40},
          {
            transform: [{scale}],
            marginTop: (-(boardSize * tileSize + 40) * (1 - scale)) / 2,
            marginLeft: (-(boardSize * tileSize + 40) * (1 - scale)) / 2,
          },
        ]}>
        {board.map((column, x) => {
          return (
            <View key={'column-' + x} style={styles.column}>
              {column.map((value, y) => {
                const position = getPosition(x, y, board.length);
                if (value === 0) {
                  return (
                    <View
                      key={position}
                      style={{width: tileSize, height: tileSize}}
                    />
                  );
                } else if (value === 'e') {
                  const c = candidateNumbers.find(n => n.position === position);
                  if (c) {
                    return (
                      <DraggableTile
                        key={position}
                        item={hideItems ? {...c, value: 0} : c}
                        style={styles.draggable}
                        textStyle={styles.draggableText}
                        tileSize={tileSize}
                      />
                    );
                  }
                  return <Tile key={position} value={0} tileSize={tileSize} />;
                } else {
                  return (
                    <Tile
                      key={position}
                      value={hideItems ? 0 : value}
                      tileSize={tileSize}
                      isPreview={true}
                    />
                  );
                }
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {alignSelf: 'center'},
  draggable: {width: 30, height: 30},
  draggableText: {fontSize: 18},
  column: {flexDirection: 'column'},
  board: {
    marginTop: -80,
    backgroundColor: '#242532',
    borderRadius: 24,
    paddingVertical: 12,
    paddingHorizontal: 12,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    transform: [{scale: 0.5}],
    borderColor: 'gold',
    borderWidth: 2,
  },

  emptyRow: {
    height: 30,
    width: 30,
  },
});
