import {Platform} from 'react-native';
import {LeaderboardScores} from '../native/types';
import {GameCenter} from '../native/GameCenter';
import {GooglePlayGames, LeaderBoards} from '../native/GooglePlayGames';

export let leaderBoardCache: LeaderboardScores | null = null;

export function setLeaderboardCache(leaderboard: LeaderboardScores | null) {
  leaderBoardCache = leaderboard;
}

export function loadScores() {
  if (Platform.OS === 'ios') {
    GameCenter.loadLeaderboardScores('total_score', true);
  } else {
    GooglePlayGames.loadLeaderboardScores(LeaderBoards.TOTAL_SCORE, true);
  }
}
