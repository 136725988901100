import React from 'react';
import {
  Image,
  PixelRatio,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import {Tile} from './Tile';
import {Draggable} from './Draggable';
import {useGameStore} from '../state/game';
import {Timer} from './Timer';
import {Difficulty} from '../core/generator';
import {format, parse} from 'date-fns';
import {Analytics} from '../analytics';
import {useSettings} from '../hooks/useSettings';
import VectorImage from './VectorImage';

/* const board2 = [
  [0, 0, 1, '+', 1, '=', 2, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 'x', 0, 0, 0],
  [0, 0, 1, '+', 1, '=', 2, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, '=', 0, 0, 0],
  [0, 0, 0, 0, 0, 0, '4', 0, 0, '÷'],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
]; */

const BOARD_PADDING = 12;
const AD_HEIGHT = 120;

export function formatDifficulty(difficulty: Difficulty) {
  return difficulty?.charAt(0).toLocaleUpperCase() + difficulty?.substring(1);
}

export function Board({
  goBack,
  openSettings,
}: {
  goBack: () => void;
  openSettings: () => void;
}) {
  const {width: deviceWidth, height: deviceHeight} = useWindowDimensions();
  const width = deviceWidth - BOARD_PADDING * 2 - 16;
  const height = deviceHeight * 0.7 - BOARD_PADDING * 2 - 16 - AD_HEIGHT;
  const board = useGameStore(state => state.board);
  const candidateNumbers = useGameStore(state => state.candidateNumbers);
  const setLayout = useGameStore(state => state.setLayout);
  const layout = useGameStore(state => state.layout);
  const difficulty = useGameStore(state => state.difficulty);
  const dailyDate = useGameStore(state => state.dailyDate);
  const gameType = useGameStore(state => state.gameType);

  const {settings} = useSettings();
  const dimension = Math.min(height, width);
  const boardSize = board.length;
  const tileSize = PixelRatio.roundToNearestPixel(
    Math.min(Math.floor(dimension / boardSize), 100),
  );

  const draggableSize = PixelRatio.roundToNearestPixel(Math.max(tileSize, 40));

  return (
    <>
      <View style={styles.header}>
        <Pressable style={styles.backButton} onPress={goBack}>
          <Image
            source={require('../assets/arrowBack.png')}
            style={styles.backImage}
          />
        </Pressable>

        <Text maxFontSizeMultiplier={2} style={styles.difficulty}>
          {difficulty?.charAt(0).toLocaleUpperCase() + difficulty?.substring(1)}
        </Text>

        <View style={styles.spacer} />

        {gameType === 'daily' && dailyDate && (
          <Text maxFontSizeMultiplier={2} style={styles.gameDay}>
            {format(parse(dailyDate + '', 'yyyyMMdd', new Date()), 'MMM dd')}
          </Text>
        )}

        <View style={styles.timerContainer}>
          <Timer />
          <Pressable
            style={({pressed}) => ({
              paddingHorizontal: 10,
              opacity: pressed ? 0.8 : 1,
            })}
            onPress={openSettings}>
            <VectorImage
              style={{width: 28, height: 28, tintColor: 'white'}}
              source={require('../assets/settings-outline.svg')}
            />
          </Pressable>
        </View>
      </View>
      <View
        onLayout={({nativeEvent}) => {
          setLayout(nativeEvent.layout);
        }}
        style={[styles.board]}>
        {board.map((column, j) => {
          return (
            <View key={'column-' + j} style={styles.column}>
              {column.map((value, i) => {
                if (value === 0) {
                  return (
                    <View
                      key={j * 10 + i}
                      style={{width: tileSize, height: tileSize}}
                    />
                  );
                } else if (value === 'e') {
                  return (
                    <Tile
                      key={j * 10 + i}
                      value={0}
                      tileSize={tileSize}
                      largeFont={settings.largeFont}
                    />
                  );
                } else {
                  return (
                    <Tile
                      key={j * 10 + i}
                      value={value}
                      tileSize={tileSize}
                      largeFont={settings.largeFont}
                    />
                  );
                }
              })}
            </View>
          );
        })}
      </View>

      <View
        style={[
          styles.candidateContainer,
          {
            width: layout.width,
            height: Math.max(
              draggableSize * 4,
              Math.floor(candidateNumbers.length / 7) * (draggableSize * 1.75),
            ),
          },
        ]}>
        {candidateNumbers.map(c => {
          return (
            <Draggable
              key={c.value + '-' + c.correctPosition}
              item={c}
              tileSize={tileSize}
              boardSize={boardSize}
              draggableSize={draggableSize}
              largeFont={settings.largeFont}
            />
          );
        })}
        <Pressable
          style={({pressed}) => [
            styles.sortButton,
            {opacity: pressed ? 0.7 : 1},
          ]}
          hitSlop={12}
          onPress={() => {
            useGameStore.setState({
              candidateNumbers: candidateNumbers
                .sort((a, b) => a.value - b.value)
                .sort((a, b) => a.position - b.position)
                .map((c, i) => ({...c, id: i})),
            });
            Analytics.logEvent('click_sort');
          }}>
          <View>
            <Text allowFontScaling={false} style={styles.sortText}>
              ⥯
            </Text>
          </View>
        </Pressable>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  spacer: {flex: 1, alignItems: 'center'},
  difficulty: {color: 'white'},
  backImage: {tintColor: 'white', width: 28, height: 28},
  backButton: {paddingLeft: 8, paddingRight: 8},
  header: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timerContainer: {marginRight: 12, flexDirection: 'row', alignItems: 'center'},
  gameDay: {
    color: 'white',
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: -1,
  },
  sortButton: {
    flexDirection: 'row',
    borderRadius: 21,
    borderWidth: 1,
    borderColor: 'white',
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 12,
    right: 12,
    position: 'absolute',
  },
  sortText: {
    color: 'white',
    fontSize: 32,
    marginTop: Platform.OS === 'ios' ? 4 : -10,
  },
  column: {flexDirection: 'column'},
  board: {
    marginTop: 6,
    backgroundColor: '#242532',
    borderRadius: 24,
    paddingVertical: BOARD_PADDING,
    paddingHorizontal: BOARD_PADDING,
    marginHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
    alignSelf: 'center',
  },
  candidateContainer: {
    marginTop: 8,
    backgroundColor: '#242532',
    flexDirection: 'row',
    paddingLeft: 12,
    borderRadius: 24,
    paddingVertical: 24,
    marginHorizontal: 12,
    gap: 8,
    minHeight: 164,
    flexWrap: 'wrap',
    position: 'relative',
    alignSelf: 'center',
  },
  emptyRow: {
    height: 25,
    width: 25,
  },
});
