import {format} from 'date-fns';
import {
  Alert,
  Pressable,
  SafeAreaView,
  Text,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {Calendar} from 'react-native-calendars';
import {Storage} from '../storage';
import {useCallback, useState} from 'react';
import {Button} from '../components/Button';
import {useGameStore} from '../state/game';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList, TabsParamList} from '../navigation/types';
import {CompositeScreenProps, useFocusEffect} from '@react-navigation/native';
import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import {Analytics} from '../analytics';

export function DailyScreen({
  navigation,
}: CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'Daily'>,
  NativeStackScreenProps<RootStackParamList>
>) {
  const backgroundStyle: ViewStyle = {
    backgroundColor: 'black',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  };
  const [dailyStatuses, setDailyStatuses] = useState(
    Storage.getDailyStatuses(),
  );
  const newDailyGame = useGameStore(state => state.newDailyGame);
  const {width} = useWindowDimensions();
  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const selectedDayStatus = dailyStatuses[selectedDate] || 'none';

  useFocusEffect(
    useCallback(() => {
      setDailyStatuses(Storage.getDailyStatuses());
    }, []),
  );

  return (
    <SafeAreaView style={backgroundStyle}>
      <Text allowFontScaling={false} style={{fontSize: 96}}>
        🏆
      </Text>

      <Calendar
        // Customize the appearance of the calendar
        style={{
          width: Math.min(width, 600),
        }}
        // Specify the current date
        initialDate={currentDate}
        minDate={'2023-09-03'}
        maxDate={currentDate}
        allowSelectionOutOfRange={false}
        // Callback that gets called when the user selects a day
        onDayPress={day => {
          console.log('selected day', day);
        }}
        markingType={'custom'}
        theme={{
          backgroundColor: 'black',
          calendarBackground: 'black',
          arrowColor: 'white',
          monthTextColor: 'white',
          dayTextColor: '#ffffff',
          textDisabledColor: '#888888',
        }}
        // Mark specific dates as marked
        dayComponent={({date, state}) => {
          const status = dailyStatuses[date!.dateString] || 'none';
          const content = status === 'completed' ? '🏆' : date?.day;
          const isSelected = date!.dateString === selectedDate;
          return (
            <Pressable
              disabled={state === 'disabled'}
              hitSlop={10}
              onPressIn={() => {
                setSelectedDate(date!.dateString);
                Analytics.logEvent('click_daily_date', {
                  date: date!.dateString,
                  isToday: date!.dateString === currentDate,
                });
              }}
              style={{
                borderRadius: 20,
                borderWidth: 2,
                borderColor: isSelected
                  ? '#4169E1'
                  : status === 'completed'
                  ? 'gold'
                  : status === 'incomplete'
                  ? 'white'
                  : 'transparent',
                width: 40,
                height: 40,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                maxFontSizeMultiplier={1.5}
                style={{
                  textAlign: 'center',
                  color: state === 'disabled' ? 'gray' : 'white',

                  fontSize: 16,
                }}>
                {content}
              </Text>
            </Pressable>
          );
        }}
        markedDates={{
          '2023-09-03': {
            selected: true,
            type: 'custom',
            customStyles: {
              container: {backgroundColor: 'green'},
              text: {color: 'black'},
            },
            dots: [],
          },
        }}
      />
      <View style={{marginTop: 32}}>
        <Button
          onPress={() => {
            if (!selectedDate) {
              return;
            }
            const date = selectedDate.replaceAll('-', '');
            const dailyGame = Storage.getDailyState(date);
            Analytics.logEvent('click_daily_play_game', {
              date: selectedDate,
              isToday: selectedDate === currentDate,
              status: selectedDayStatus,
            });
            if (!dailyGame) {
              const selectedDay = parseInt(date, 10);
              const gameCreated = newDailyGame(selectedDay);
              if (!gameCreated) {
                Alert.alert('Oops', 'Something went wrong, please try again.');
                return;
              }
            } else {
              const state = JSON.parse(dailyGame);
              useGameStore.setState(state);
            }
            navigation.navigate('Game');
          }}
          disabled={selectedDayStatus === 'completed'}
          style={selectedDayStatus === 'completed' && {backgroundColor: 'gray'}}
          variant="primary">
          {selectedDayStatus === 'completed'
            ? 'Completed'
            : selectedDayStatus === 'incomplete'
            ? 'Continue'
            : 'Play'}
        </Button>
      </View>
    </SafeAreaView>
  );
}
