import {
  Alert,
  AppState,
  AppStateStatus,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {endOfDay, format, intervalToDuration} from 'date-fns';
import {GameState, useGameStore} from '../state/game';
import {useMMKVObject} from 'react-native-mmkv';
import {
  NavigationProp,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import {RootStackParamList} from '../navigation/types';
import {useEffect, useRef, useState} from 'react';
import {Analytics} from '../analytics';
import {useStreakStatus} from '../hooks/useStreakStatus';
import {getStreakDays} from '../core/streak';

function getButtonText(dailyGameState: GameState['gameState']) {
  switch (dailyGameState) {
    case 'none':
      return 'Play';
    case 'incomplete':
      return 'Continue';
    case 'completed':
      return 'Completed';
  }
}

function padZero(num: number) {
  return num < 10 ? '0' + num : num;
}

function getDuration() {
  const now = Date.now();
  const {
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({
    start: now,
    end: endOfDay(now),
  });
  const s = padZero(seconds);
  const m = padZero(minutes);

  let formatted = `${m}:${s}`;
  if (hours > 0) {
    const h = padZero(hours);

    formatted = `${h}:${formatted}`;
  }
  return formatted;
}

export function useCountdown() {
  const [formattedText, setFormattedText] = useState<string>(getDuration());
  const interval = useRef<NodeJS.Timer>();

  useEffect(() => {
    interval.current = setInterval(() => {
      setFormattedText(getDuration());
    }, 1000);
    return () => {
      clearInterval(interval.current);
    };
  }, [interval]);

  return formattedText;
}

export function DailyChallengeCard() {
  const today = format(new Date(), 'yyyyMMdd');
  const [_forceUpdate, setForceUpdate] = useState<string>();

  useFocusEffect(() => {
    // Refresh date on page changes
    const date = format(new Date(), 'yyyyMMdd');
    setForceUpdate(date);
  });

  useEffect(() => {
    // Refresh date on app focus change
    const focus = (state: AppStateStatus) => {
      if (state === 'active') {
        const date = format(new Date(), 'yyyyMMdd');
        setForceUpdate(date);
      }
    };
    const sub = AppState.addEventListener('change', focus);
    return () => {
      sub.remove();
    };
  }, []);

  return <DailyCardView key={today} />;
}

function DailyCardView() {
  const today = format(new Date(), 'yyyyMMdd');
  const [dailyGame] = useMMKVObject<GameState>(`daily-${today}`);
  const streakStatus = useStreakStatus();
  const dailyGameState = dailyGame?.gameState || 'none';
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const newDailyGame = useGameStore(state => state.newDailyGame);
  const countdown = useCountdown();
  const streak = getStreakDays(streakStatus);

  return (
    <Pressable
      disabled={dailyGameState === 'completed'}
      style={({pressed}) => [
        {
          alignSelf: 'center',
          alignItems: 'center',
          borderRadius: 8,
          marginTop: 16,
          width: 250,
          opacity: pressed ? 0.7 : 1,
          backgroundColor: '#222222',
        },
      ]}
      onPress={() => {
        if (!dailyGame) {
          const today = parseInt(format(new Date(), 'yyyyMMdd'), 10);
          const gameCreated = newDailyGame(today);
          if (!gameCreated) {
            Alert.alert('Oops', 'Something went wrong, please try again.');
            return;
          }
        } else {
          useGameStore.setState(dailyGame);
        }
        Analytics.logEvent('click_daily_game_card', {
          date: today,
          status: dailyGameState,
        });
        navigation.navigate('Game');
      }}>
      <Text style={styles.title}>{`🏆 ${format(
        new Date(),
        'MMM d',
      )} Daily Challenge`}</Text>

      {dailyGameState !== 'completed' && (
        <>
          <Text style={styles.date}>
            {streak > 0
              ? `Keep your ${streak} day streak 🔥`
              : 'Start your streak today!'}
          </Text>
          <Text style={[styles.date, {fontVariant: ['tabular-nums']}]}>
            {countdown}
          </Text>
        </>
      )}
      <View
        style={[
          styles.button,
          {
            backgroundColor:
              dailyGameState === 'completed' ? '#00AA00' : '#4169E1',
          },
        ]}>
        <Text style={styles.buttonText}>{getButtonText(dailyGameState)}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  buttonText: {
    color: 'white',
    paddingVertical: 8,
    paddingHorizontal: 20,
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  button: {
    borderRadius: 20,
    backgroundColor: '#4169E1',
    marginBottom: 12,
  },
  date: {
    color: 'white',
    marginBottom: 12,
    fontSize: 13,
    fontWeight: '500',
    textAlign: 'center',
  },
  title: {
    color: 'white',
    paddingVertical: 12,
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center',
  },
});
