import {
  ActivityIndicator,
  Alert,
  Linking,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  View,
} from 'react-native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/types';
import Purchases, {PurchasesPackage} from 'react-native-purchases';
import {useEffect, useState} from 'react';
import {useSettings} from '../hooks/useSettings';
import {applyPremium} from '../core/premium';
import {useIsPremium} from '../hooks/useIsPremium';

export function SettingsScreen({}: NativeStackScreenProps<
  RootStackParamList,
  'Settings'
>) {
  const [lifetimePackage, setLifetimePackage] = useState<PurchasesPackage>();
  const [loading, setLoading] = useState(false);

  const {isPremium, setIsPremium} = useIsPremium();
  const [isRestoring, setIsRestoring] = useState(false);

  const {settings, setSettings} = useSettings();
  useEffect(() => {
    Purchases.getCustomerInfo().then(customer => {
      const hasPremium = !!customer.entitlements.active['premium'];
      setIsPremium(hasPremium);
      if (!hasPremium) {
        Purchases.getOfferings().then(offers => {
          console.log(offers.current?.lifetime);
          if (offers.current?.lifetime) {
            setLifetimePackage(offers.current.lifetime);
          }
        });
      } else {
        applyPremium();
      }
    });
  }, [setIsPremium]);

  const restorePurchases = () => {
    setIsRestoring(true);
    Purchases.restorePurchases()
      .then(customerInfo => {
        const isSubscribed = customerInfo.entitlements.active['premium'];
        if (isSubscribed) {
          setIsPremium(true);
          applyPremium();
        } else {
          Alert.alert(
            'Restoration failed',
            "You don't have any prior purchase to restore.",
          );
        }
      })
      .finally(() => {
        setIsRestoring(false);
      });
  };
  return (
    <SafeAreaView style={{flex: 1}}>
      <ScrollView contentContainerStyle={{minHeight: '100%'}}>
        {!isPremium && lifetimePackage && (
          <View
            style={{
              backgroundColor: '#550055',
              margin: 20,
              borderRadius: 8,
              padding: 20,
              flexDirection: 'row',
            }}>
            <View style={{flex: 1}}>
              <Text style={{fontSize: 20, color: 'white', flex: 1}}>
                {'Remove Ads'}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: 'white',
                  marginTop: 8,
                  marginBottom: 20,
                }}>
                {
                  'All banner ads and pop-up ads will be removed permanently except opt-in rewarded ads.'
                }
              </Text>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Pressable
                  disabled={loading}
                  onPress={() => {
                    setLoading(true);
                    Purchases.purchasePackage(lifetimePackage)
                      .then(res => {
                        const hasPremium =
                          !!res.customerInfo.entitlements.active['premium'];
                        if (hasPremium) {
                          applyPremium();
                        }
                        setIsPremium(hasPremium);
                        setLoading(false);
                      })
                      .catch(err => {
                        console.log('err', err);
                        setLoading(false);
                      });
                  }}
                  style={{
                    backgroundColor: 'green',
                    alignSelf: 'flex-start',
                    paddingVertical: 12,
                    paddingHorizontal: 20,
                    borderRadius: 40,
                  }}>
                  {loading && (
                    <ActivityIndicator
                      style={StyleSheet.absoluteFill}
                      color="white"
                    />
                  )}
                  <Text
                    style={{
                      fontSize: 18,
                      color: 'white',
                      fontWeight: '600',
                      opacity: loading ? 0 : 1,
                    }}>
                    {lifetimePackage.product.priceString}
                  </Text>
                </Pressable>
              </View>
            </View>
            <View
              style={{
                borderRadius: 40,
                borderWidth: 3,
                borderColor: 'red',
                width: 80,
                height: 80,
                margin: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Text
                style={{
                  fontSize: 28,
                  color: 'white',
                }}>
                {'ADS'}
              </Text>
              <View
                style={{
                  width: 4,
                  height: 80,
                  backgroundColor: 'red',
                  position: 'absolute',
                  transform: [{rotate: '45deg'}],
                }}
              />
            </View>
          </View>
        )}
        {!isPremium && Platform.OS === 'ios' && (
          <Pressable
            onPress={restorePurchases}
            style={({pressed}) => ({
              opacity: pressed ? 0.7 : 1,
              marginVertical: 10,
              flexDirection: 'row',
              gap: 16,
            })}>
            <Text style={{color: 'white', fontSize: 20, marginLeft: 20}}>
              Restore Purchase
            </Text>
            {isRestoring && <ActivityIndicator color="white" />}
          </Pressable>
        )}
        <View
          style={{
            height: 2,
            marginVertical: 10,
            width: '100%',
            backgroundColor: '#222222',
          }}
        />
        <SettingsRow
          title="Sound Effects"
          value={settings.isSoundEffectsEnabled}
          onValueChange={value => setSettings({isSoundEffectsEnabled: value})}
        />
        <SettingsRow
          title="Large Font"
          value={settings.largeFont}
          onValueChange={value => setSettings({largeFont: value})}
        />

        <View style={{flex: 1}} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 20,
            paddingBottom: 72,
            paddingTop: 20,
          }}>
          <Pressable
            onPress={() =>
              Linking.openURL('https://crossmath.cancit.com/privacy-policy')
            }
            style={({pressed}) => ({
              opacity: pressed ? 0.7 : 1,
            })}>
            <Text style={{color: 'white', textDecorationLine: 'underline'}}>
              Privacy Policy
            </Text>
          </Pressable>
          <Pressable
            onPress={() =>
              Linking.openURL('https://captain.cancit.com/eula.html')
            }
            style={({pressed}) => ({
              opacity: pressed ? 0.7 : 1,
            })}>
            <Text style={{color: 'white', textDecorationLine: 'underline'}}>
              Terms of Use
            </Text>
          </Pressable>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

function SettingsRow({
  title,
  value,
  onValueChange,
}: {
  title: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 20,
        marginVertical: 10,
      }}>
      <Text style={{fontSize: 20, color: 'white'}}>{title}</Text>
      <Switch value={value} onValueChange={onValueChange} />
    </View>
  );
}
