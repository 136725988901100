import {Storage} from '../storage';
import {Ads} from './Ads';

export function initPremium() {
  if (Storage.getIsPremium()) {
    applyPremium();
  }
}

export function applyPremium() {
  Ads.isAdsDisabled = true;
}
