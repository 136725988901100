import {MMKV} from 'react-native-mmkv';
import {GameState} from '../state/game';

const storage = new MMKV();

export const globalStateKey = 'globalState';
export const classicGameStatsKey = 'classic-game-stats';
export const totalScoreKey = 'total-score';
export const isRateUsShown = 'is-rate-us-shown';
export const streakStatusKey = 'streak-status';
export const streakProtectionKey = 'streak-protection';
export const settingsKey = 'settings';
export const isPremiumKey = 'isPremium';

const sessionCountKey = 'session-count';
class StorageImpl {
  getGlobalState() {
    return storage.getString(globalStateKey);
  }

  setGlobalState(state: string) {
    return storage.set(globalStateKey, state);
  }

  reset() {
    storage.clearAll();
  }

  getDailyState(date: string) {
    return storage.getString(`daily-${date}`);
  }

  setDailyState(date: string, state: string) {
    if (!date) {
      return;
    }
    return storage.set(`daily-${date}`, state);
  }

  getDailyKeys = () => {
    return storage.getAllKeys().filter(key => key.startsWith('daily-'));
  };

  getGameStats() {
    return storage.getString(classicGameStatsKey);
  }

  setGameStats(stats: string) {
    return storage.set(classicGameStatsKey, stats);
  }

  getTotalScore() {
    return storage.getNumber(totalScoreKey) || 0;
  }

  setTotalScore(score: number) {
    return storage.set(totalScoreKey, score);
  }

  getDailyStatuses() {
    return this.getDailyKeys().reduce((acc, cur) => {
      const gameString = storage.getString(cur);
      if (gameString) {
        try {
          const game = JSON.parse(gameString) as GameState;

          const date = game.dailyDate + '';
          const dateFormatted =
            date.substring(0, 4) +
            '-' +
            date.substring(4, 6) +
            '-' +
            date.substring(6, 8);
          acc[dateFormatted] = game.gameState;
        } catch (err) {
          console.log(err);
        }
      }
      return acc;
    }, {} as {[key: string]: GameState['gameState']});
  }

  getSessionCount() {
    return storage.getNumber(sessionCountKey) || 0;
  }

  incrementSessionCount() {
    const sessionCount = this.getSessionCount();
    return storage.set(sessionCountKey, sessionCount + 1);
  }

  getIsRateUsShown() {
    return storage.getBoolean(isRateUsShown) || false;
  }

  setIsRateUsShown() {
    return storage.set(isRateUsShown, true);
  }

  setStreakStatus(streakStatus: string) {
    return storage.set(streakStatusKey, streakStatus);
  }

  getStreakStatus() {
    return storage.getString(streakStatusKey);
  }

  getStreakProtection() {
    return storage.getNumber(streakProtectionKey) || 0;
  }

  incrementStreakProtection() {
    const streakProtectionCount = this.getStreakProtection();
    return storage.set(streakProtectionKey, streakProtectionCount + 1);
  }

  decrementStreakProtection() {
    const streakProtectionCount = this.getStreakProtection();
    return storage.set(streakProtectionKey, streakProtectionCount - 1);
  }

  getSettings() {
    const settings = storage.getString(settingsKey);
    if (!settings) {
      return null;
    }
    try {
      return JSON.parse(settings);
    } catch {
      return null;
    }
  }

  getIsPremium() {
    return storage.getBoolean(isPremiumKey);
  }
}

export const Storage = new StorageImpl();
