import {
  ActivityIndicator,
  Pressable,
  PressableProps,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

type ButtonProps = {
  variant: 'primary' | 'secondary';
  style?: ViewStyle;
  loading?: boolean;
  textStyle?: TextStyle;
  children: React.ReactNode | undefined;
} & PressableProps;

export function Button({
  variant,
  loading,
  textStyle,
  style,
  children,
  ...rest
}: ButtonProps) {
  return (
    <Pressable
      disabled={loading}
      style={({pressed}) => [
        {
          alignSelf: 'center',
          alignItems: 'center',
          borderRadius: 40,
          paddingVertical: 6,
          width: 300,
          opacity: pressed ? 0.7 : 1,
        },
        variant === 'primary' && {backgroundColor: '#4169E1'},
        variant === 'secondary' && {borderColor: '#4169E1', borderWidth: 2},
        style,
      ]}
      {...rest}>
      {loading && (
        <ActivityIndicator
          color="white"
          style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}
        />
      )}
      <Text
        maxFontSizeMultiplier={2}
        style={[
          {
            color: 'white',
            paddingVertical: 12,
            fontSize: 18,
            fontWeight: '500',
            opacity: loading ? 0 : 1,
          },
          textStyle,
        ]}>
        {children}
      </Text>
    </Pressable>
  );
}
