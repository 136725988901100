export class AnalyticsClass {
  async init() {}

  logScreenView = () => {};

  logEvent = () => {};

  logSelectItem = () => {};
}

export const Analytics = new AnalyticsClass();

Analytics.init();
