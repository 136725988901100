import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Alert, Platform, Pressable, StyleSheet, Text, View} from 'react-native';
import {RootStackParamList} from '../navigation/types';
import {useGameStore} from '../state/game';
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
} from '@gorhom/bottom-sheet';
import {Difficulty} from '../core/generator';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Analytics} from '../analytics';
import {ClassicGameStat, getGameStats} from '../core/stats';

export function DifficultyScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'DifficultyScreen'>) {
  const newGame = useGameStore(state => state.newGame);
  const gameStats = getGameStats();
  const {bottom} = useSafeAreaInsets();
  const [isDismissing, setIsDismissing] = useState(false);

  const snap = 320 + bottom + (Platform.OS === 'android' ? 10 : 0);
  // hooks
  const bottomSheetRef = useRef<BottomSheet>(null);

  // variables
  const snapPoints = useMemo(() => [snap, snap], [snap]);

  // callbacks
  const handleSheetChange = useCallback((index: number) => {
    // eslint-disable-next-line no-console
    console.log('handleSheetChange', index);
  }, []);

  // renders
  const renderBackdrop = useCallback(
    (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        appearsOnIndex={1}
        pressBehavior="close"
      />
    ),
    [],
  );

  const selectDifficulty = (difficulty: Difficulty) => {
    setIsDismissing(true);
    bottomSheetRef?.current?.close({duration: 200});
    setTimeout(() => {
      Analytics.logEvent('select_difficulty_' + difficulty, {});
      const gameCreated = newGame(difficulty);
      if (gameCreated) {
        navigation.replace('Game');
      } else {
        Alert.alert(
          'Oops',
          'Something went wrong while creating the game, please try again.',
        );
      }
    }, 50);
  };

  return (
    <BottomSheet
      ref={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={renderBackdrop}
      animateOnMount={true}
      enablePanDownToClose={true}
      onClose={() => {
        if (!isDismissing) {
          navigation.pop();
        }
      }}
      backgroundStyle={styles.sheetBackground}
      handleIndicatorStyle={styles.indicator}
      onChange={handleSheetChange}>
      <Text style={styles.newGame}>{'New Game'}</Text>
      <View>
        <DifficultyButton
          onPress={() => selectDifficulty('easy')}
          icon={'🙂'}
          text="Easy"
        />
        <View style={styles.seperator} />
        <DifficultyButton
          onPress={() => selectDifficulty('medium')}
          icon={'😐'}
          text="Medium"
          requirement={{
            count: 5,
            difficulty: 'easy',
            stat: gameStats.easy,
            visible: true,
          }}
        />
        <View style={styles.seperator} />
        <DifficultyButton
          onPress={() => selectDifficulty('hard')}
          icon={'😯'}
          text="Hard"
          requirement={{
            count: 10,
            difficulty: 'medium',
            stat: gameStats.medium,
            visible: gameStats.easy.gameCompleted >= 5,
          }}
        />
        <View style={styles.seperator} />
        <DifficultyButton
          onPress={() => selectDifficulty('extreme')}
          icon={'🏄'}
          text="Extreme"
          requirement={{
            count: 15,
            difficulty: 'hard',
            stat: gameStats.hard,
            visible: gameStats.medium.gameCompleted >= 10,
          }}
        />
      </View>
    </BottomSheet>
  );
}

function titleCase(str: string) {
  return str.charAt(0).toLocaleUpperCase() + str.substring(1);
}

function DifficultyButton({
  requirement,
  icon,
  text,
  onPress,
}: {
  requirement?: {
    stat: ClassicGameStat;
    difficulty: Difficulty;
    count: number;
    visible: boolean;
  };
  icon: string;
  text: string;
  onPress: () => void;
}) {
  const disabled =
    requirement && requirement.stat.gameCompleted < requirement.count;
  const requirementText = requirement
    ? requirement.count -
      requirement.stat.gameCompleted +
      ' ' +
      titleCase(requirement.difficulty)
    : undefined;
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={({pressed}) => ({
        opacity: pressed ? 0.7 : 1,
        paddingHorizontal: 24,
        paddingVertical: 20,
        flexDirection: 'row',
        alignItems: 'center',
      })}>
      <Text style={[styles.buttonText, {opacity: disabled ? 0.6 : 1}]}>
        {disabled ? '🔒' : icon}
      </Text>

      <Text
        style={[
          styles.buttonText,
          {marginLeft: 12, opacity: disabled ? 0.6 : 1},
        ]}>
        {text}
      </Text>

      {requirement?.visible && (
        <Text
          style={[
            styles.buttonText,
            {
              fontSize: 11,
              color: 'white',
              opacity: disabled ? 1 : 0,
              flex: 1,
              textAlign: 'right',
            },
          ]}>
          Complete {requirementText} games to unlock
        </Text>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  sheetBackground: {backgroundColor: 'rgb(20,20,20)'},
  indicator: {backgroundColor: 'transparent'},
  newGame: {
    color: 'white',
    paddingHorizontal: 24,
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
  seperator: {
    width: '100%',
    height: 1,
    backgroundColor: '#333333',
  },
  button: {
    backgroundColor: '#4169E1',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 4,
    width: 200,
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: '500',
  },
});
