import {create} from 'zustand';
import {
  BoardOperation,
  Difficulty,
  checkOperation,
  getOperationCoordinates,
  getPosition,
  getRandomDiffuculty,
  puzzle,
} from '../core/generator';
import {LayoutRectangle} from 'react-native';
import {Storage} from '../storage';
import {AudioPlayer} from '../core/AudioPlayer';
import {Analytics} from '../analytics';
import {addGameCompletedStat, addGameStartedStat} from '../core/stats';

import {addScore, getGameScore} from '../core/score';
import {scheduleReminders} from '../core/reminders';
import {Achivements} from '../core/achievements';
import {addStreak} from '../core/streak';
import {isTodaysDailyGame} from '../utils/utils';

export type CandidateNumber = {
  id: number;
  value: number;
  position: number;
  correctPosition: number;
  isWrong: boolean;
};

type CandidatePositions = {[key: number]: CandidateNumber & {index: number}};

export type GameType = 'normal' | 'daily';

export type GameState = {
  board: (string | number)[][];
  layout: LayoutRectangle;
  map: BoardOperation[];
  difficulty: Difficulty;
  candidateNumbers: CandidateNumber[];
  gameState: 'none' | 'incomplete' | 'completed';
  gameDurationInSeconds: number;
  gameType: GameType;
  dailyDate?: number; // YYYYMMDD
  newGame: (
    difficulty: Difficulty,
    gameType?: GameType,
    dailyDate?: number,
  ) => boolean;
  newDailyGame: (date: number) => boolean;
  setLayout: (layout: LayoutRectangle) => void;
  moveNumber: (id: number, position: number) => void;
  canMoveItem: (id: number, position: number) => boolean;
  checkBoard: () => void;
  incrementGameDuration: () => void;
};

export const useGameStore = create<GameState>((set, get) => ({
  board: [],
  map: [],
  gameType: 'normal',
  dailyDate: undefined,
  gameState: 'none',
  gameDurationInSeconds: 0,
  layout: {height: 0, width: 0, y: 0, x: 0},
  candidateNumbers: [],
  difficulty: 'easy',
  setLayout: (layout: LayoutRectangle) => {
    set({layout});
  },
  moveNumber: (id, position) => {
    const {candidateNumbers, checkBoard} = get();

    const newCandidates = [...candidateNumbers];

    const index = newCandidates.findIndex(c => c.id === id);

    const target = newCandidates.findIndex(c => c.position === position);
    if (position > -1 && target > -1) {
      newCandidates[target].position = newCandidates[index].position;
    }
    newCandidates[index].position = position;

    if (position !== -1) {
      AudioPlayer.playPop();
    }

    set({candidateNumbers: newCandidates});
    checkBoard();
  },
  canMoveItem: (id, position) => {
    const {candidateNumbers} = get();
    console.log('move position', position);
    console.log(
      'move position',
      candidateNumbers.map(c => c.correctPosition),
    );

    return candidateNumbers.findIndex(c => c.correctPosition === position) > -1;
  },
  newGame: (
    difficulty: Difficulty,
    gameType: GameType = 'normal',
    dailyDate: number | undefined = undefined,
  ) => {
    try {
      const {board, candidateNumbers, map} = puzzle(difficulty);
      set({
        board,
        map,
        gameType,
        dailyDate,
        gameState: 'incomplete',
        candidateNumbers: candidateNumbers.map((c, index) => ({
          id: index,
          value: c.value,
          correctPosition: c.position,
          position: -1,
          isWrong: false,
        })),
        gameDurationInSeconds: 0,
        difficulty,
      });
      Analytics.logEvent(dailyDate ? 'new_daily_game' : 'new_game', {
        difficulty,
        gameType,
        dailyDate,
      });
      if (gameType === 'normal') {
        addGameStartedStat(difficulty);
      }
      return true;
    } catch (err) {
      return false;
    }
  },
  newDailyGame: (date: number) => {
    const {newGame} = get();
    return newGame(getRandomDiffuculty(), 'daily', date);
  },
  checkBoard: () => {
    console.log('checkBoard');
    const {
      board,
      map,
      candidateNumbers,
      difficulty,
      gameDurationInSeconds,
      gameType,
      dailyDate,
    } = get();
    const newCandidateNumbers = candidateNumbers.map(c => ({
      ...c,
      isWrong: false,
    }));
    const candidatePositions = candidateNumbers.reduce((m, c, index) => {
      m[c.position] = {...c, index};
      return m;
    }, {} as {[key: number]: CandidateNumber & {index: number}});
    console.log('candidatePositions', candidatePositions);
    let hasError = false;

    map.forEach(({direction, x, y}) => {
      const {
        leftCoordinates,
        operandCoordinates,
        rightCoordinates,
        resultCoordinates,
      } = getOperationCoordinates({x, y, direction});

      const operand = String(board[operandCoordinates.x][operandCoordinates.y]);

      const left = getPositionValue(leftCoordinates, board, candidatePositions);
      const right = getPositionValue(
        rightCoordinates,
        board,
        candidatePositions,
      );
      const result = getPositionValue(
        resultCoordinates,
        board,
        candidatePositions,
      );

      const operationResult = checkOperation({left, operand, right, result});

      if (operationResult === 'incomplete') {
        return;
      }

      if (operationResult === 'incorrect') {
        hasError = true;
        console.log(
          `Result is not correct ${left} ${operand} ${right} is not equal to ${result}`,
        );
        // Mark wrong numbers
        markWrongNumber(
          leftCoordinates,
          candidatePositions,
          newCandidateNumbers,
          board.length,
        );
        markWrongNumber(
          rightCoordinates,
          candidatePositions,
          newCandidateNumbers,
          board.length,
        );
        markWrongNumber(
          resultCoordinates,
          candidatePositions,
          newCandidateNumbers,
          board.length,
        );
      }
    });
    if (!hasError && !candidatePositions[-1]) {
      console.log('game completed!');
      Analytics.logEvent('game_completed', {difficulty, gameDurationInSeconds});
      set({candidateNumbers: newCandidateNumbers, gameState: 'completed'});

      addScore(getGameScore(difficulty, candidateNumbers.length));
      if (gameType === 'normal') {
        addGameCompletedStat(difficulty);
        Achivements.submitGameCompleted(difficulty);
      } else if (gameType === 'daily') {
        Achivements.submitDailyGameCompleted();
        if (isTodaysDailyGame(dailyDate, gameType)) {
          addStreak();
          scheduleReminders({excludeToday: true});
        }
      }
    }
    set({candidateNumbers: newCandidateNumbers});
  },
  incrementGameDuration: () => {
    set(s => ({gameDurationInSeconds: s.gameDurationInSeconds + 1}));
  },
}));

function markWrongNumber(
  {x, y}: {x: number; y: number},
  candidatePositions: CandidatePositions,
  newCandidateNumbers: CandidateNumber[],
  boardSize: number,
) {
  if (candidatePositions[getPosition(x, y, boardSize)]) {
    newCandidateNumbers[
      candidatePositions[getPosition(x, y, boardSize)].index
    ].isWrong = true;
  }
}

export function getPositionValue(
  {x, y}: {x: number; y: number},
  board: (number | string)[][],
  candidatePositions: CandidatePositions,
) {
  const value = board[x][y];
  if (value === 'e') {
    if (candidatePositions[getPosition(x, y, board.length)]) {
      return candidatePositions[getPosition(x, y, board.length)].value;
    }
  }
  return value;
}

if (Storage.getGlobalState()) {
  const state = JSON.parse(Storage.getGlobalState()!) as GameState;
  useGameStore.setState(state);
}

useGameStore.subscribe(state => {
  const stateCopy = {...state};
  Object.keys(stateCopy).forEach(_key => {
    const key = _key as keyof typeof stateCopy;
    if (typeof stateCopy[key as keyof typeof stateCopy] === 'function') {
      delete stateCopy[key as keyof typeof stateCopy];
    }
  });
  if (state.gameType === 'normal') {
    Storage.setGlobalState(JSON.stringify(stateCopy));
  } else if (state.gameType === 'daily' && state.dailyDate) {
    Storage.setDailyState(state.dailyDate + '', JSON.stringify(stateCopy));
  }
});
