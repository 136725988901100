import {Dimensions} from 'react-native';
import {Storage} from '../storage';
import {AudioPlayer} from './AudioPlayer';

export type Settings = {
  isSoundEffectsEnabled: boolean;
  largeFont: boolean;
};

export const DEFAULT_SETTINGS = {
  isSoundEffectsEnabled: true,
  largeFont: Dimensions.get('window').fontScale > 1.2 ? true : false,
};
console.log('fontScale', Dimensions.get('window').fontScale);

export function getSettings(): Settings {
  return {...DEFAULT_SETTINGS, ...(Storage.getSettings() || {})};
}

export function applySettings(settings: Settings) {
  if (settings.isSoundEffectsEnabled) {
    AudioPlayer.isSoundEffectsEnabled = settings.isSoundEffectsEnabled;
  }
}

export function initSettings() {
  applySettings(getSettings());
}
