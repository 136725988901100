/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 */

import React, {useEffect} from 'react';
import {
  DarkTheme,
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native';

import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {GameScreen} from './screens/GameScreen';
import {HomeScreen} from './screens/HomeScreen';
import {RootStackParamList, TabsParamList} from './navigation/types';
import {GameCompletedScreen} from './screens/GameCompletedScreen';
import {AudioPlayer} from './core/AudioPlayer';
import {DifficultyScreen} from './screens/DifficultyScreen';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import VectorImage from './components/VectorImage';
import {DailyScreen} from './screens/DailyScreen';
import {Analytics} from './analytics';
import {Ads} from './core/Ads';
import {Storage} from './storage';
import {showRateUsIfNeeded} from './core/rateUs';
import {getStreakStatus} from './core/streak';
import {GameEndStreakScreen} from './screens/GameEndStreakScreen';
import {GameEndLeaderboardScreen} from './screens/GameEndLeaderboardScreen';
import {StreakProtection} from './screens/StreakProtection';
import {SettingsScreen} from './screens/SettingsScreen';
import Purchases, {LOG_LEVEL} from 'react-native-purchases';
import {Platform} from 'react-native';
import {initSettings} from './core/settings';
import {applyPremium, initPremium} from './core/premium';

const Stack = createNativeStackNavigator<RootStackParamList>();
const Tab = createBottomTabNavigator<TabsParamList>();

const setupPayments = async () => {
  Purchases.setLogLevel(LOG_LEVEL.DEBUG);

  if (Platform.OS === 'ios') {
    await Purchases.configure({apiKey: 'appl_TTDFxBkQHTaaVovjXvGDEWYOoCf'});
  } else if (Platform.OS === 'android') {
    await Purchases.configure({apiKey: 'public_google_sdk_key'});
  }
  Purchases.getCustomerInfo().then(c => {
    if (c.entitlements.active['premium']) {
      applyPremium();
    }
  });
};

async function bootstrap() {
  await Ads.init();
  await Analytics.init();

  Storage.incrementSessionCount();
  getStreakStatus();
  showRateUsIfNeeded();
  initSettings();
  initPremium();
}

bootstrap();

function App(): JSX.Element {
  const routeNameRef = React.useRef<string>();
  const navigationRef =
    React.useRef<NavigationContainerRef<RootStackParamList>>(null);

  useEffect(() => {
    setupPayments();
  }, []);
  return (
    <GestureHandlerRootView style={{flex: 1, backgroundColor: 'black'}}>
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name;
        }}
        theme={DarkTheme}
        onStateChange={async () => {
          const previousRouteName = routeNameRef.current;
          const currentRouteName =
            navigationRef.current?.getCurrentRoute()?.name;
          if (previousRouteName !== currentRouteName) {
            Analytics.logScreenView({
              screen_name: currentRouteName,
              screen_class: currentRouteName,
            });
          }
          routeNameRef.current = currentRouteName;
        }}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            orientation: 'portrait',
            animation: 'fade',
          }}>
          <Stack.Screen name="Tabs" component={Tabs} />
          <Stack.Screen name="Game" component={GameScreen} />
          <Stack.Screen name="GameCompleted" component={GameCompletedScreen} />
          <Stack.Screen
            name="DifficultyScreen"
            component={DifficultyScreen}
            options={{presentation: 'transparentModal'}}
          />
          <Stack.Screen name="GameEndStreak" component={GameEndStreakScreen} />
          <Stack.Screen
            name="GameEndLeaderboard"
            component={GameEndLeaderboardScreen}
          />
          <Stack.Screen
            name="StreakProtection"
            component={StreakProtection}
            options={{presentation: 'transparentModal'}}
          />
          <Stack.Screen
            name="Settings"
            component={SettingsScreen}
            options={{headerShown: true}}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </GestureHandlerRootView>
  );
}

function Tabs() {
  return (
    <Tab.Navigator
      screenOptions={({route}) => ({
        // eslint-disable-next-line react/no-unstable-nested-components
        tabBarIcon: ({focused, color, size}) => {
          if (route.name === 'Home') {
            return (
              <VectorImage
                style={{
                  width: size,
                  height: size,
                  tintColor: color,
                }}
                source={
                  focused
                    ? require('./assets/home.svg')
                    : require('./assets/home-outline.svg')
                }
              />
            );
          } else if (route.name === 'Daily') {
            return (
              <VectorImage
                style={{
                  width: size,
                  height: size,
                  tintColor: color,
                }}
                source={
                  focused
                    ? require('./assets/calendar.svg')
                    : require('./assets/calendar-outline.svg')
                }
              />
            );
          }
        },
        tabBarStyle: {
          backgroundColor: '#111111',
          borderTopWidth: 0,
        },
        tabBarItemStyle: {paddingBottom: 6, justifyContent: 'center'},
        tabBarInactiveTintColor: 'gray',
        headerShown: false,
      })}>
      <Tab.Screen name="Home" component={HomeScreen} />
      <Tab.Screen name="Daily" component={DailyScreen} />
    </Tab.Navigator>
  );
}
AudioPlayer.init();

export default App;
