import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Platform, StatusBar, StyleSheet, View} from 'react-native';

import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {RootStackParamList} from '../navigation/types';
import {Text} from 'react-native';
import {Button} from '../components/Button';
import Animated, {
  FadeIn,
  interpolateColor,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import VectorImage from '../components/VectorImage';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {leaderBoardCache} from '../core/leaderboard';
import {useTotalScore} from '../hooks/useTotalScore';

/* let items = [
  {name: 'Bado', rank: 1, score: 4000},
  {name: 'Lore', rank: 2, score: 3700},
  {name: 'Kahki', rank: 3, score: 3800},
  {name: 'Bold', rank: 4, score: 3000},
  {name: 'Polski', rank: 5, score: 2500},
  {name: 'Yorsu', rank: 6, score: 2200},
  {name: 'Falk', rank: 7, score: 2000},
  {name: 'Torli', rank: 8, score: 2000},
  {name: 'Polski12', rank: 9, score: 2500},
  {name: 'Yorsu223', rank: 10, score: 2200},
  {name: 'Fal23k', rank: 11, score: 2000},
  {name: 'Torli2323', rank: 12, score: 1000},
  {name: 'Torli1', rank: 13, score: 1000},
  {name: 'Torli2', rank: 14, score: 1000},
  {name: 'Torli3', rank: 15, score: 1000},
  {name: 'Torli4', rank: 16, score: 1000},
  {name: 'Torli5', rank: 17, score: 1000},
  {name: 'Torli6', rank: 18, score: 1000},
  {name: 'Torli7', rank: 19, score: 1000},
  {name: 'Torli8', rank: 20, score: 1000},
  {name: 'Torli9', rank: 21, score: 1000},
  {name: 'Torli10', rank: 22, score: 1000},
  {name: 'Torli11', rank: 23, score: 1000, isCurrent: true},
  {name: 'Torli12', rank: 24, score: 1000},
  {name: 'Torli13', rank: 25, score: 1000},
  {name: 'Torli14', rank: 26, score: 1000},
  {name: 'Torli15', rank: 27, score: 1000},
  {name: 'Torli16', rank: 28, score: 1000},
  {name: 'Torli17', rank: 29, score: 1000},
]; */

function getScrollPosition(rank: number, firstRank: number) {
  return Math.max((rank - firstRank - 3) * 70, 0);
}

export function GameEndLeaderboardScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, 'GameEndLeaderboard'>) {
  const newScore = useTotalScore();
  const currentRank = leaderBoardCache!.localUser.rank;

  const items: {name: string; rank: number; score: number}[] =
    leaderBoardCache!.scores.map((s: any) => ({
      name: s.alias,
      rank: s.rank,
      score: s.score,
      isCurrent: s.alias === leaderBoardCache!.localUser.alias,
    }));

  const firstRank = items[0].rank;

  // const newScore = 2960;
  const newRankPosition =
    items.find((a, i) => {
      if (i < 1) {
        return false;
      }
      if (i >= items.length - 1) {
        return false;
      }
      return (
        items[i - 1].score > newScore &&
        items[i + 1].score < newScore &&
        items[i].score <= newScore
      );
    })?.rank || firstRank;
  const scrollRef = useRef<Animated.ScrollView>(null);

  const [newRank, setNewRank] = useState(0);

  const offsetY = useSharedValue<number>(
    getScrollPosition(currentRank, firstRank),
  );

  const animatedProps = useAnimatedProps<any>(() => ({
    contentOffset: {
      x: 0,
      y: offsetY.value,
    },
  }));

  const scrollToIndex = useCallback(
    (index: number) => {
      offsetY.value = withTiming(getScrollPosition(index, firstRank), {
        duration: Platform.OS === 'ios' ? 1000 : 10,
      });
    },
    [offsetY, firstRank],
  );

  useEffect(() => {
    setTimeout(() => {
      setNewRank(newRankPosition);
      setTimeout(() => {
        scrollToIndex(newRankPosition);
      }, 5);
    }, 2000);
  }, [scrollRef, scrollToIndex, newRankPosition]);

  const title = useMemo(() => {
    if (newRankPosition === currentRank) {
      if (currentRank === 1) {
        return 'You are the one, number 1!';
      }
      const index = items.findIndex(i => i.rank === newRankPosition) - 1;
      return (
        'You are only ' +
        (items[index].score - newScore) +
        ' points away from ' +
        items[index].name +
        '🎯'
      );
    } else {
      return 'You moved up in the\nLeaderboard!';
    }
  }, [newRankPosition, currentRank, items, newScore]);

  const {top, bottom} = useSafeAreaInsets();
  return (
    <View
      style={[
        styles.backgroundStyle,
        {paddingTop: top, paddingBottom: bottom},
      ]}>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={styles.backgroundStyle.backgroundColor}
      />
      <View style={{flex: 2, alignItems: 'center', justifyContent: 'center'}}>
        <VectorImage
          style={{
            width: 48,
            height: 48,
            marginVertical: 16,
            tintColor: 'white',
          }}
          source={require('../assets/leaderboard.svg')}
        />
        <Animated.Text
          style={{
            color: 'white',
            fontSize: 20,
            marginBottom: 20,
            marginHorizontal: 40,
            textAlign: 'center',
          }}>
          {title}
        </Animated.Text>

        <Animated.ScrollView
          animatedProps={animatedProps}
          // ref={scrollRef}
          //  contentOffset={{y: offsetY}}
          entering={FadeIn.duration(300).delay(1000)}
          style={{
            width: '100%',
            maxWidth: 320,
            marginBottom: 20,
            height: '100%',
            flex: 1,
          }}
          contentContainerStyle={{
            height: items.length * 70,
          }}>
          {items.map(item => {
            return (
              <AnimatedLeaderboardRow
                key={item.name}
                item={item}
                newRank={newRank}
                newScore={newScore}
                currentRank={leaderBoardCache!.localUser.rank}
                firstRank={firstRank}
              />
            );
          })}
        </Animated.ScrollView>
      </View>
      <Animated.View
        entering={FadeIn.delay(
          newRankPosition === currentRank ? 1000 : 3400,
        ).duration(300)}
        style={{
          opacity: 1,
          marginBottom: 40,
        }}>
        <Button
          variant="primary"
          onPress={() => {
            navigation.replace('GameCompleted');
          }}>
          Continue
        </Button>
      </Animated.View>
    </View>
  );
}

function getNewPosition(
  rank: number,
  newRank: number,
  isCurrent: boolean,
  currentRank: number,
) {
  'worklet';
  if (!newRank) {
    return 0;
  }

  if (isCurrent) {
    return 70 * (newRank - rank);
  }

  if (rank < currentRank && rank >= newRank) {
    return 70;
  }
  return 0;
}
function getNewRank(
  rank: number,
  newRank: number,
  isCurrent: boolean,
  currentRank: number,
) {
  'worklet';
  if (!newRank) {
    return rank;
  }

  if (isCurrent) {
    return newRank;
  }

  if (rank < currentRank && rank >= newRank) {
    return rank + 1;
  }
  return rank;
}
function AnimatedLeaderboardRow({
  item,
  newRank,
  currentRank,
  newScore,
  firstRank,
}: any) {
  const isRival = currentRank === newRank && item.rank === newRank - 1;
  const colorValue = useSharedValue(0);
  useEffect(() => {
    if (isRival) {
      colorValue.value = withRepeat(
        withSequence(
          withTiming(1, {duration: 2000}),
          withTiming(0, {duration: 2000}),
        ),
        0,
        true,
      );
    }
  }, [colorValue, isRival]);

  const animatedStyle = useAnimatedStyle(() => {
    const color = interpolateColor(
      colorValue.value,
      [0, 1],
      ['transparent', '#4169E1'],
      'RGB',
    );

    return {
      position: 'absolute',
      right: 0,
      left: 0,
      height: 70,
      top: (item.rank - firstRank) * 70,
      //  borderWidth: item.isCurrent ? 1 : 0,
      zIndex: item.isCurrent ? 1 : 0,
      // borderColor: 'white',
      backgroundColor: item.isCurrent
        ? 'green'
        : isRival
        ? color
        : 'transparent',
      transform: [
        {
          translateY: newRank
            ? withTiming(
                getNewPosition(item.rank, newRank, item.isCurrent, currentRank),
                {
                  duration: 1000,
                },
              )
            : 0,
        },
      ],
    };
  }, [item.isCurrent, newRank]);
  return (
    <Animated.View style={animatedStyle}>
      <LeaderboardRow
        name={isRival ? item.name + ' 🎯' : item.name}
        rank={getNewRank(item.rank, newRank, item.isCurrent, currentRank)}
        score={newRank && item.isCurrent ? newScore : item.score}
      />
    </Animated.View>
  );
}

function LeaderboardRow({
  name = 'Bado',
  rank,
  score,
}: {
  name: string;
  rank: number;
  score: number;
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        width: 320,
        height: 70,
        padding: 12,
      }}>
      {/*      <View
        style={{
          width: 48,
          height: 48,
          backgroundColor: 'white',
          borderRadius: 30,
        }}
      /> */}
      <Text
        maxFontSizeMultiplier={2}
        style={{color: 'white', opacity: 0.8, fontSize: 16}}>
        {rank}
      </Text>
      <View style={{flex: 1, justifyContent: 'center'}}>
        <Text
          maxFontSizeMultiplier={1.5}
          style={{color: 'white', fontSize: 16}}
          numberOfLines={1}>
          {name}
        </Text>
        <Text
          maxFontSizeMultiplier={1.5}
          style={{color: 'white', fontSize: 14, opacity: 0.8}}>
          {score} points
        </Text>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  spacer: {flex: 1},
  backgroundStyle: {
    backgroundColor: 'black',
    height: '100%',
  },
});
